import { Button, Col, Empty, message, Row, Space, Spin } from "antd";
import { technicianApi } from "api/technician.api";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Service } from "types/service";
import { Technician as TechnicianType } from "types/technician";
import { Technician } from "views/MerchantDetailPage/components/TechnicianList/components/Technician";

message.config({
  maxCount: 1,
});

export const SelectTechnician = ({
  servicesSelected,
  onSubmitOK,
  onPrevious,
  onNextStep,
}: {
  servicesSelected?: Service[];
  onSubmitOK: (technicianId: number | undefined) => void;
  onPrevious: () => void;
  onNextStep: () => void;
}) => {
  const param = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const [technicianData, setTechnicianData] = useState<TechnicianType[]>();
  const [loading, setLoading] = useState(false);
  const [merchantId, setmerchantId] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>();

  //Auto select technician if have technicianId param
  useEffect(() => {
    if (searchParam.get("technicianId")) {
      const technicianId = searchParam.get("technicianId");
      setSelectedId(technicianId);
    }
  }, [searchParam.get("technicianId")]);

  //Fetch technician by id merchant
  useEffect(() => {
    setmerchantId(param.idMerchant || "");
  }, [param.idMerchant]);

  useEffect(() => {
    if (merchantId) fetchTechnician();
  }, [merchantId]);

  const fetchTechnician = async () => {
    setLoading(true);
    const serviceIds = servicesSelected?.map((service) => service.id);
    const res = await technicianApi.availableServices({ serviceIds });

    setLoading(false);
    setTechnicianData(res.data);
  };

  //Submit
  const handleSubmit = () => {
    onSubmitOK(selectedId || undefined);
    onNextStep();
  };

  return (
    <Spin spinning={loading}>
      {technicianData && technicianData?.length > 0 ? (
        <Row gutter={24} className="technician-select-box">
          <Col
            lg={{ span: 6 }}
            xs={{ span: 12 }}
            md={{ span: 8 }}
            key={0}
            style={{ marginBottom: "1em" }}
          >
            {/* No preference */}
            <Technician
              selectedId={selectedId}
              onClick={() => {
                if (selectedId == undefined) {
                  message.info("No preference is default");
                } else {
                  setSelectedId(undefined);
                }
              }}
            />
          </Col>
          {technicianData?.map((technician) => {
            return (
              <Col
                lg={{ span: 6 }}
                xs={{ span: 12 }}
                md={{ span: 8 }}
                key={technician.id}
                style={{ marginBottom: "1em" }}
              >
                <Technician
                  technician={technician}
                  selectedId={selectedId}
                  onClick={() => {
                    if (selectedId == technician.id) setSelectedId(undefined);
                    else setSelectedId(technician.id);
                  }}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Space
          style={{ padding: "2em", width: "100%", justifyContent: "center" }}
        >
          <Empty />
        </Space>
      )}

      <div className="steps-action">
        <Button
          className="custom-btn outline"
          style={{ margin: "0 8px" }}
          onClick={onPrevious}
        >
          Previous
        </Button>
        <Button
          type="primary"
          className="custom-btn"
          onClick={handleSubmit}
          // loading={loading}
        >
          Next
        </Button>
      </div>
    </Spin>
  );
};
