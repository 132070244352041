import { UserAddOutlined } from "@ant-design/icons";
import { Avatar, Divider, List, Rate as RateAntd, Space } from "antd";
import React from "react";
import { Rate } from "types/rate";
import { unixToDate } from "utils/formatDate";

export const Comment = ({ data }: { data: Rate }) => {
  return (
    <div className="comment">
      <div className="header">
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <div className="userInfo">
            <Space>
              <Avatar
                icon={<UserAddOutlined />}
                size="large"
                src={data.customerProfile.avatar}
              />
              <div>
                <div className="name">
                  {data.customerProfile.firstName +
                    " " +
                    data.customerProfile.lastName}
                </div>
                <div className="date">{unixToDate(data.createdAt)}</div>
              </div>
            </Space>
          </div>
          <RateAntd disabled defaultValue={data.rateStar} />
        </Space>
      </div>
      <div className="content">
        <p>{data.rateComment}</p>
      </div>
    </div>
  );
};
