import { Merchant } from "./merchant";

export interface Promotion {
  id: number;
  createdAt: number;
  updatedAt: number;
  title: string;
  code: string;
  kind: PromotionKind;
  startAt: number;
  endAt: number;
  amount: number; //Nếu là reward thì mua bằng point với giá amount
  quantity: number; //Số lượng phát
  used: number; //Đã dùng
  discountBy: DiscountBy;
  conditionMinPrice: number; //Giá trị đơn tối thiểu
  discountValue: number; //Giá trị giảm
  discountMaxValue: number; //Giá tối đa nếu giảm theo %
  isLimitDiscount: boolean; //Có Giới hạn khi giảm theo % ?
  numberOfDayNoSee: number; //Số ngay chưa quay lại
  type: PromotionType; //Số ngay chưa quay lại
  isDeleted: boolean;
  // promotionCustomers: PromotionCustomer[];
  merchant: Merchant;
}

export enum PromotionType {
  Normal = "NORMAL", //
  Reminder = "REMINDER", //Nhắc khách lâu quá chưa quay lại
  Birthday = "BIRTHDAY", //Khách có sinh nhật
}

export enum DiscountBy {
  Amount = "AMOUNT",
  Percent = "PERCENT",
}

export enum PromotionKind {
  Promotion = "PROMOTION", //Được tặng
  Reward = "REWARD", //Mua bằng point
}
