import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { ForgotPassword } from "../ForgotPasswordPage/steps/ForgotPassword";
import { LoginPhone } from "./components/LoginPhone";
import { LoginSocial } from "./components/LoginSocial";
import { Register } from "./components/Register";
import "./styles/LoginPage.scss";

export const LoginPage = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams("");
  const [loginParam, setLoginParam] = useState("");
  const location = useLocation();

  useEffect(() => {
    setLoginParam(searchParams.get("type") || "");
    console.log(searchParams.get("type"));
  }, [searchParams]);

  const handleBack = () => {
    // @ts-ignore:
    if (location?.state?.requireLogin) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="login-page">
      <div>
        <div>
          <div className="login-header">
            <Button type="text" onClick={handleBack} className="btn-back">
              <ArrowLeftOutlined />
            </Button>
            <img
              src={logo}
              alt=""
              className="logo"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="login-container">
          {loginParam == "loginPhone" && <LoginPhone />}
          {loginParam == "register" && <Register />}
          {loginParam == "" && <LoginSocial />}

          <div className="text-center login-footer">
            <p>
              {loginParam == "register"
                ? "Have a booker account?"
                : "Don't have a booker account?"}
            </p>
            {loginParam == "register" ? (
              <Button type="link" onClick={() => navigate("/login")}>
                Log in now
              </Button>
            ) : (
              <Button type="link" onClick={() => navigate("?type=register")}>
                Sign up now
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
