import { ShopOutlined } from "@ant-design/icons";
import { AppointmentPagePublic } from "views/AppoinmentPagePublic/AppoinmentPagePublic";
import { ClientLayout } from "layouts/ClientLayout";
import { AuthProvider } from "provider/AuthProvider";
import React from "react";
import { RouteObject } from "react-router-dom";
import { NotFoundPage } from "views/404/NotFoundPage";
import { AppointmentPage } from "views/AppointmentPage/AppointmentPage";
import { BookingPage } from "views/BookingPage/BookingPage";
import { ForgotPasswordPage } from "views/ForgotPasswordPage/ForgotPasswordPage";
import { Homepage } from "views/Homepage/Homepage";
import { LoginPage } from "views/Login/LoginPage";
import { MerchantDetailPage } from "views/MerchantDetailPage/MerchantDetailPage";
import { ProfilePage } from "views/ProfilePage/ProfilePage";
import { SearchPage } from "views/SearchPage/SearchPage";
import { RatePublicPage } from "views/RatePublicPage/RatePublicPage";

interface Route extends RouteObject {
  title?: string;
  children?: Route[];
  icon?: React.ReactNode;
  breadcrumb?: string;
  isHide?: boolean;
}

export const adminRoutes: Route[] = [
  {
    title: "Appointment",
    icon: <ShopOutlined />,
    path: "/",
    breadcrumb: "Appointment",
    element: <AppointmentPage title="Appointment" />,
  },
  {
    title: "Appointment",
    icon: <ShopOutlined />,
    path: "/appointment",
    breadcrumb: "Appointment",
    element: <AppointmentPage title="Appointment" />,
  },
  {
    title: "Profile",
    icon: <ShopOutlined />,
    path: "/profile",
    breadcrumb: "Profile",
    element: <ProfilePage title="Profile" />,
    index: true,
  },
  // {
  //   title: "Home Page",
  //   icon: <ShopOutlined />,
  //   path: "/",
  //   breadcrumb: "Home",
  //   index: true,
  //   element: <Homepage />,
  // },
  {
    title: "Merchant Detail",
    icon: <ShopOutlined />,
    path: "/merchant/:idMerchant",
    breadcrumb: "Merchant",
    element: <MerchantDetailPage title="Merchant details" />,
  },
  {
    title: "Booking",
    icon: <ShopOutlined />,
    path: "/merchant/:idMerchant/booking",
    breadcrumb: "Booking",
    element: <BookingPage title="Booking" />,
  },
  {
    title: "Appointment",
    icon: <ShopOutlined />,
    path: "/public/appointment/:publicCode",
    breadcrumb: "Search",
    element: <AppointmentPagePublic title="Appointment" />,
  },
  {
    title: "Appointment",
    icon: <ShopOutlined />,
    path: "/rate/:publicCode",
    breadcrumb: "rate",
    element: <RatePublicPage title="Rate" />,
  },
];

const routes: Route[] = [
  {
    element: <ClientLayout />,
    children: adminRoutes,
    //
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/register",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export { routes };
