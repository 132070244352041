import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { authApi } from "api/auth.api";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { AuthProvider } from "provider/AuthProvider";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "store/userStore";
import { Customer } from "types/customer";
import { getTitle } from "utils";
import "./styles/LoginPage.scss";

const { Item: FormItem } = Form;

export const ProfilePage = observer(({ title }: { title: string }) => {
  const rules: Rule[] = [{ required: true }];
  const [form] = useForm();
  const navigate = useNavigate();
  const [data, setData] = useState<Customer>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("sda userstore", toJS(userStore.info));
    const formData = {
      phone: data?.phone,
      email: data?.customerProfile.email,
      firstName: data?.customerProfile?.firstName,
      lastName: data?.customerProfile?.lastName,
      avatar: data?.customerProfile?.avatar,
    };

    form.setFieldsValue({ ...formData });
  }, [data]);

  useEffect(() => {
    fetchData();
    document.title = getTitle(title);
  }, []);

  // useEffect(() => {
  //   console.log("userStore.infosd", toJS(userStore.info));
  // }, [userStore.info]);

  const fetchData = async () => {
    setLoading(true);
    const res = await authApi.profile();
    setLoading(false);
    setData(res.data);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const updateProfile = async () => {
    await form.validateFields();
    const profile = form.getFieldsValue();
    console.log(profile);

    try {
      setLoading(true);
      const res = await authApi.update({ profile });
      message.success("Update profile successfully");
      // userStore.getProfile();
    } finally {
      setLoading(false);
      console.log();
    }
  };

  return (
    <AuthProvider>
      <div className="login-page">
        {console.log("info", userStore.info)}
        <div>
          <div className="profile-header">
            <Button className="btn-back" type="text" onClick={handleBack}>
              <ArrowLeftOutlined />
            </Button>
            <h1>Edit Profile</h1>
          </div>
          <div className="login-container">
            <Form layout="vertical" form={form} onFinish={updateProfile}>
              <Form.Item
                shouldUpdate={true}
                style={{ marginBottom: "0" }}
                required
                rules={rules}
              >
                {() => {
                  return (
                    <Form.Item label="Avatar" name="avatar">
                      <SingleImageUpload
                        onUploadOk={(path: string) => {
                          console.log("onUploadOk:", path);
                          form.setFieldsValue({
                            avatar: path,
                          });
                        }}
                        imageUrl={form.getFieldValue("avatar")}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <FormItem
                label="First name"
                name="firstName"
                key={"firstName"}
                required
                rules={rules}
              >
                <Input />
              </FormItem>
              <FormItem
                label="Last name"
                name="lastName"
                key={"lastName"}
                required
                rules={rules}
              >
                <Input />
              </FormItem>
              <FormItem
                label="Email address"
                name="email"
                key={"email"}
                required
                rules={rules}
              >
                <Input />
              </FormItem>
              <FormItem
                label="Phone number"
                name="phone"
                key={"phone"}
                required
                rules={rules}
              >
                <Input disabled />
              </FormItem>

              <FormItem>
                <Button
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loading}
                  type="primary"
                  size="large"
                >
                  Save
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
});
