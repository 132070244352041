import { serviceCategoryApi } from "api/serviceCategory.api";
import React, { useEffect, useState } from "react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { ServiceCategory } from "types/service";
import { checkSlug } from "utils/checkSlug";

const config = {
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4,
    },
  },
};

export const ServiceCateBooking = ({
  merchantId,
  onSelectCate,
}: {
  merchantId: number | string;
  onSelectCate: (cateId: number) => void;
}) => {
  const [serviceCate, setServiceCate] = useState<ServiceCategory[]>();
  const [loading, setLoading] = useState(false);
  const [selectedCateId, setSelectedCateId] = useState<number>();

  useEffect(() => {
    if (merchantId) {
      fetchServiceCate(merchantId);
    }
  }, [merchantId]);

  const fetchServiceCate = async (merchantId: number | string) => {
    setLoading(true);
    const isSlug = checkSlug(merchantId);
    let res;
    if (isSlug) {
      res = await serviceCategoryApi.findAll({ merchantSlug: merchantId });
    } else {
      res = await serviceCategoryApi.findAll({ merchantId });
    }

    setLoading(false);
    setServiceCate(res.data.serviceCategories);
    setSelectedCateId(res.data.serviceCategories[0].id);
    onSelectCate(res.data.serviceCategories[0].id);
  };

  const handleSelect = (id: number) => {
    if (selectedCateId == id) {
      setSelectedCateId(undefined);
    } else {
      setSelectedCateId(id);
    }
  };

  return (
    <div style={{ marginTop: "1em" }}>
      <h1>Select service category</h1>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        slidesPerView={6}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        {...config}
      >
        {serviceCate?.map((cate) => {
          const selectedClass =
            cate.id == selectedCateId ? "service-selected" : "";
          return (
            <SwiperSlide key={cate.id}>
              <div
                className={`service service-cate ${selectedClass}`}
                onClick={() => {
                  handleSelect(cate.id);
                  onSelectCate(cate.id);
                }}
              >
                {cate.name}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
