import { Query } from "@testing-library/react";
import { Col, Modal, Rate, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { rateApi } from "api/rate.api";
import React, { useEffect, useState } from "react";
import { QueryParam, RateQueryParam } from "types/query";
import { Rate as rateType } from "types/rate";
import { Comment } from "./Comment";

export const AllReviewModal = ({
  visible,

  merchantId,
  onClose,
}: {
  visible: boolean;
  merchantId: number;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [rates, setRates] = useState<rateType[]>();
  const [totalRate, setTotalRate] = useState(0);
  const [rateSummary, setRateSummary] =
    useState<{ total: number; star: number }[]>();
  const [query, setQuery] = useState<QueryParam>({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (visible && merchantId) {
      fetchSummary();
      fetchRate();
    }
  }, [merchantId, visible]);

  useEffect(() => {
    fetchRate();
  }, [query]);

  const fetchRate = async () => {
    setLoading(true);
    const dataRes = await rateApi.getRate({ merchantId, ...query });
    setLoading(false);
    setRates(dataRes.data.rates);
  };

  const fetchSummary = async () => {
    setLoading(true);
    const summaryRes = await rateApi.summary({ merchantId });

    const totalRate = summaryRes.data?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.total;
      },
      0
    );

    setTotalRate(totalRate);

    setLoading(false);
    setRateSummary(summaryRes.data);
  };

  const calcAverageStar = () => {
    const totalStar = rateSummary?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.star * rate.total;
      },
      0
    );

    const totalRate = rateSummary?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.total;
      },
      0
    );

    if (totalRate == 0) return 0;
    return (totalStar / totalRate).toFixed(1);
  };

  const calcStarPercent = (star: number) => {
    const totalOfStarItem = rateSummary?.find(
      (rate) => rate.star == star
    )?.total;
    if (totalOfStarItem && totalOfStarItem > 0) {
      return (totalOfStarItem * 100) / totalRate;
    } else {
      return 0;
    }
  };

  return (
    <Modal
      className="modal-fullwidth all-review-modal"
      onCancel={onClose}
      visible={visible}
      style={{ top: 7 }}
      title="All reviews"
      confirmLoading={loading}
      footer={false}
    >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} lg={{ span: 9 }} style={{ marginBottom: "2em" }}>
          <div className="review-container">
            <div className="review">
              <Rate disabled defaultValue={5} /> <br />
              <Space>
                <p>
                  <b>{calcAverageStar()} Star </b>
                  <span>{totalRate} ratings</span>
                </p>
              </Space>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 15 }} xs={{ span: 24 }} className="comment-box">
          {rates?.map((rate: rateType) => {
            return <Comment data={rate} key={rate.id} />;
          })}
        </Col>
      </Row>
    </Modal>
  );
};
