import { Col, Row, Space } from "antd";
import React from "react";
import {
  DiscountBy,
  Promotion,
  PromotionKind,
  PromotionType,
} from "types/promotion";
import { formatVND } from "utils/formatVND";
import "./style/voucher.scss";
const moment = require("moment");

export const Voucher = ({
  data,
  onClick,
}: {
  data: Partial<Promotion>;
  onClick?: () => void;
}) => {
  let voucherClass = "";
  if (data.kind === PromotionKind.Reward) {
    voucherClass = "reward";
  } else {
    switch (data.type) {
      case PromotionType.Birthday:
        voucherClass = "birthday";
        break;
      case PromotionType.Reminder:
        voucherClass = "reminder";
        break;
      case PromotionType.Normal:
        voucherClass = "normal";
        break;
    }
  }

  return (
    <div onClick={onClick} className={`voucher ${voucherClass}`}>
      <div className="kind-tag">{data.kind}</div>
      <div className="discount-tag">
        {" "}
        - {data.discountBy == DiscountBy.Amount ? "$" : null}
        {data.discountValue}
        {data.discountBy == DiscountBy.Percent ? "%" : null}
      </div>

      <div className="content">
        <h2 className="text-center title">{data.title}</h2>

        <div className="voucher-info">
          <Row gutter={16}>
            {data.kind === PromotionKind.Reward ? (
              <Space style={{ width: "100%", justifyContent: "end" }}>
                {" "}
                <div className="tags">
                  <span className="tag">
                    AMOUNT: {formatVND(data.amount || 0)} Points
                  </span>
                </div>
              </Space>
            ) : null}
          </Row>
        </div>
      </div>
    </div>
  );
};
