import { Carousel } from "antd";
import React from "react";
import { MerchantGallery } from "types/merchantGallery";
import defaultImg from "../../assets/images/default_merchant_img.jpg";
import "./style/carousel.scss";

export default function CarouselComponent({
  images,
  height,
}: {
  images: Partial<MerchantGallery>[];
  height?: number;
}) {
  return (
    <>
      {images.length > 0 ? (
        <Carousel
          autoplay
          autoplaySpeed={3000}
          className="carousel-component"
          style={{ height }}
        >
          {images.map((image) => {
            return (
              <div className="item" key={image.id}>
                <img src={image?.image} alt="" />
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Carousel autoplay className="carousel-component">
          <div className="item">
            <img src={defaultImg} alt="" />
          </div>
        </Carousel>
      )}
    </>
  );
}
