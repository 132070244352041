
export const $url = (url?: string) => {
    if (!url) {
        return '';
    }
    if (url.includes('http')) {
        return url;
    }

    return process.env.REACT_APP_API_URL + url;
}