import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HourglassTwoTone,
} from "@ant-design/icons";
import { Button, Col, Empty, message, Row, Space, Typography } from "antd";
import { merchantApi } from "api/merchant.api";
import { technicianApi } from "api/technician.api";
import { checkSlug } from "utils/checkSlug";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { WorkingHours } from "types/workingHours";
import { timeBlockApi } from "api/timeBlock.api";
import { TimeBlock } from "types/timeblock";
import { unixToDate, unixToFullTime, unixToTime } from "utils/formatDate";
import { appointmentApi } from "api/appointment.api";
import { Appointment } from "types/appointment";

interface ITimeBlock {
  startAt: number;
  endAt: number;
}

export const SelectTime = ({
  technicianId,
  onSubmitOK,
  onNextStep,
  onPrevious,
}: {
  technicianId: number | undefined;
  onSubmitOK: (bookingTime: number) => void;
  onNextStep?: () => void;
  onPrevious: () => void;
}) => {
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState(30);
  const [days, setDays] = useState<any[]>();
  const [times, setTimes] = useState<any[]>();
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [isScrollStart, setIsScrollStart] = useState(true);
  const [selectedTime, setSelectedTime] = useState<number>();
  const [timeBlock, setTimeBlock] = useState<ITimeBlock[]>([]);
  const [workingHours, setWorkingHours] = useState<WorkingHours[]>([]);
  const [selectedDay, setSelectedDay] = useState<number>(
    moment().startOf("day").unix()
  );

  const daysBoxRef = useRef<HTMLDivElement | null>(null);

  //create time arr
  useEffect(() => {
    fetchWorkingHours();
  }, [technicianId, param.merchantId]);

  useEffect(() => {
    if (technicianId && technicianId != 0) fetchTimeBlock();
  }, [technicianId, selectedDay]);

  useEffect(() => {
    let dayArr = [];
    let timeArr = [];
    // debugger;
    const dayIndex = moment.unix(selectedDay).day();
    let find;

    find = workingHours.find((hour) => hour.day == dayIndex && hour.enable);

    //Day
    for (let i = 0; i < 29; i++) {
      dayArr.push(moment().startOf("day").add(i, "day"));
    }

    let startHour = find?.from;
    const endHour = moment(find?.to, "HH:mm")
      .subtract(interval, "minutes")
      .format("HH:mm");

    timeArr.push(moment(startHour, "HH,mm"));

    if (startHour && endHour) {
      // debugger;

      while (startHour && startHour && startHour < endHour) {
        const time: any = moment(startHour, "HH:mm").add(
          interval || 15,
          "minutes"
        );

        const isWorkingHour = checkIsWorkingHour(
          time.format("HH:mm"),
          selectedDay
        );

        if (isWorkingHour) {
          timeArr.push(time);
        }

        startHour = time.format("HH:mm");
      }
    } else {
      timeArr = [];
    }

    setTimes(timeArr);
    setDays(dayArr);
  }, [workingHours, selectedDay, interval]);

  const checkIsWorkingHour = (hour: string, date: number) => {
    // debugger;
    if (workingHours.length == 0) {
      return false;
    }

    const day = moment.unix(date).day();

    const find = workingHours.find((e) => +e.day == day && e.enable);
    if (!find) {
      return false;
    }
    return hour >= find.from && hour <= find.to;
  };

  const fetchWorkingHours = async () => {
    //fetch technician w-hours. if not have fetch merchant work-hours
    setLoading(true);
    let resTechnician;
    if (technicianId && technicianId != 0) {
      resTechnician = await technicianApi.detail(technicianId || 0);
      if (resTechnician.data.workingHourJson) {
        setWorkingHours(JSON.parse(resTechnician.data.workingHourJson));
        console.log("TECHNICIAN WORKING");
      }
    }

    //fetch merchant w-hours
    let res;
    if (checkSlug(param.idMerchant)) {
      res = await merchantApi.detailSlug(param.idMerchant || "");
    } else {
      res = await merchantApi.detail(param.idMerchant || 0);
    }
    setInterval(res.data.timeSlotInterval || 30);
    if (res.data.workingHourJson && !resTechnician?.data?.workingHourJson) {
      const merchantWorkingHour = JSON.parse(res.data.workingHourJson);
      setWorkingHours(merchantWorkingHour);
      console.log("MERCHANT WORKING");
    }
  };

  const fetchTimeBlock = async () => {
    // let timeblockArr: ITimeBlock = [];
    setLoading(true);
    const resTimeBlock = await timeBlockApi.findAll({
      technicianId,
      page: 1,
      limit: 10,
      date: moment.unix(selectedDay).format("YYYY-MM-DD"),
    });
    const resBusyTime = await appointmentApi.getNew({
      technicianId,
      page: 1,
      limit: 10,
      from: moment.unix(selectedDay).startOf("day").unix(),
      to: moment.unix(selectedDay).endOf("day").unix(),
    });

    const timeBlock1 = resTimeBlock.data.timeBlocks.map((time: TimeBlock) => {
      return {
        startAt: time.startAt,
        endAt: time.endAt,
      };
    });
    const timeBlock2 = resBusyTime.data.appointments.map(
      (time: Appointment) => {
        return {
          startAt: time.firstServiceStart,
          endAt: time.serviceEnd,
        };
      }
    );
    setLoading(false);

    setTimeBlock([...timeBlock1, ...timeBlock2]);
    console.log("timeblock", [...timeBlock1, ...timeBlock2]);
  };

  //reset time when click new day
  useEffect(() => {
    setSelectedTime(undefined);
  }, [selectedDay]);

  const selectDate = (unixDay: number) => {
    if (unixDay) {
      setSelectedDay(unixDay);
    }
  };

  const selectTime = (unixTime: number) => {
    if (unixTime) {
      setSelectedTime(unixTime);
    }
  };

  const handleSubmit = () => {
    //Check tecnician busyy

    if (selectedDay && selectedTime) {
      const dayFormated = moment.unix(selectedDay).format("YYYY-MM-DD");
      const timeFormated = moment.unix(selectedTime).format("HH:mm:ss");
      const bookingTime = `${dayFormated} ${timeFormated}`;

      onSubmitOK(moment(bookingTime + "").unix());
      // onNextStep();
    } else {
      message.error("Please select time");
    }
  };

  //handle next/pre day
  useEffect(() => {
    daysBoxRef.current?.scrollTo({ left: scrollOffset });
  }, [scrollOffset]);

  const next = () => {
    const maxWidth = daysBoxRef.current?.scrollWidth;
    const offSetWidth = daysBoxRef.current?.offsetWidth;

    //Check max scroll width
    if (offSetWidth && maxWidth && scrollOffset + offSetWidth < maxWidth) {
      setIsScrollStart(false);
      setScrollOffset((preValue) => preValue + offSetWidth);
    }
    // else {
    //   setIsScrollEnd(true);
    // }
  };

  const pre = () => {
    const offSetWidth = daysBoxRef.current?.offsetWidth;
    if (offSetWidth && scrollOffset - offSetWidth > 0) {
      setIsScrollEnd(false);
      setScrollOffset((preValue) => preValue - offSetWidth);
    } else {
      setIsScrollStart(true);
      setScrollOffset(0);
    }
  };

  const checkTimeBlock = (timeBlock: ITimeBlock[], timeToBeCheck: any) => {
    // console.log("timeblock", timeBlock);
    const timetobeCheckMoment: moment.Moment = timeToBeCheck;
    const time = timetobeCheckMoment.format("HH:mm");
    const momentString =
      moment.unix(selectedDay).format("YYYY-MM-DD") + " " + time;
    let isTimeBlock = false;
    const unixBeCheck = moment(momentString, "YYYY-MM-DD HH:mm").unix();
    // debugger;
    //Check if  starttime >= timetobeCheck >= endtime  return true
    timeBlock.forEach((tl) => {
      if (tl.startAt <= unixBeCheck && unixBeCheck <= tl.endAt) {
        return (isTimeBlock = true);
      }
    });

    return isTimeBlock;
  };

  return (
    <div className="selecte-time-box">
      {/* Render Day */}
      <div className="date-selector">
        <Typography.Title level={3}>Select a day</Typography.Title>
        <div className="date-container" id="box" ref={daysBoxRef}>
          {days?.map((e, index) => {
            const isSelect = e.unix() == selectedDay;
            return (
              <div
                key={index}
                className={`date ${isSelect ? "date-selected" : undefined}`}
                onClick={() => selectDate(e.unix())}
              >
                <p style={{ fontSize: 15, fontWeight: 100 }}>
                  {" "}
                  {e.format("MMM")}
                </p>
                <p>{e.format("ddd DD")}</p>
              </div>
            );
          })}
        </div>

        <Button
          disabled={isScrollEnd}
          type="default"
          className="next btn-slider"
          onClick={next}
          icon={<ArrowRightOutlined />}
        ></Button>
        <Button
          disabled={isScrollStart}
          type="default"
          className="pre btn-slider"
          onClick={pre}
          icon={<ArrowLeftOutlined />}
        ></Button>
      </div>

      <div className="time-container" id="box">
        {/* Render time */}
        <Typography.Title level={3}>Select time</Typography.Title>
        <Row gutter={24}>
          {times &&
          times?.length > 0 &&
          moment.unix(selectedDay).format("DD") == moment().format("DD")
            ? times //===>> filter nhung thoi gian da qua
                ?.filter((m) => m.unix() > moment().unix())
                .map((e, index) => {
                  const isTimeBlock = checkTimeBlock(timeBlock, e);
                  const isSelect = selectedTime == e.unix();
                  return (
                    <Col lg={{ span: 4 }} xs={{ span: 8 }} key={index}>
                      <div
                        className={`time ${isSelect ? "time-selected" : ""} ${
                          isTimeBlock ? "disable" : ""
                        }`}
                        onClick={() => {
                          if (isTimeBlock) return;
                          selectTime(e.unix());
                        }}
                      >
                        {e.format("LT")}
                      </div>
                    </Col>
                  );
                })
            : times?.map((e, index) => {
                //==>>Render time cua nhung ngay sau do (Full time)
                const isTimeBlock = checkTimeBlock(timeBlock, e);
                const isSelect = selectedTime == e.unix();
                return (
                  <Col lg={{ span: 4 }} xs={{ span: 8 }} key={index}>
                    <div
                      className={`time ${
                        isSelect ? "time-selected" : undefined
                      } ${isTimeBlock ? "disable" : ""}`}
                      onClick={() => {
                        if (isTimeBlock) return;
                        selectTime(e.unix());
                      }}
                    >
                      {e.format("LT")}
                    </div>
                  </Col>
                );
              })}

          {times?.length == 0 && (
            <Col span={24} style={{ marginBottom: "2em" }}>
              <Space
                direction="vertical"
                style={{ alignItems: "center", width: "100%" }}
              >
                <Empty description="This Staff is not available" />
                <Button
                  type="primary"
                  style={{ marginTop: "1em" }}
                  onClick={onPrevious}
                >
                  Try with other staff
                </Button>
              </Space>
            </Col>
          )}
        </Row>
        <div className="steps-action">
          {times && times?.length > 0 && (
            <>
              {" "}
              <Button
                className="custom-btn outline"
                style={{ margin: "0 8px" }}
                onClick={onPrevious}
              >
                Previous
              </Button>{" "}
              <Button
                type="primary"
                className="custom-btn"
                onClick={handleSubmit}
                //   loading={loading}
              >
                Done
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
