import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { authApi } from "api/auth.api";
import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { Navigate, useNavigate } from "react-router-dom";

export const ResetCode = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [isPass, setIsPass] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    await form.validateFields();
    const { password, code } = form.getFieldsValue();
    try {
      await authApi.passwordReset({ password, code });
      message.success(
        "Reset password successfully. You will be redirected to the login page in 3 seconds"
      );

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const checkCode = async () => {
    await form.validateFields();
    const code = form.getFieldsValue();
    try {
      const res = await authApi.confirmCode(code);
      if (res.status) {
        setIsPass(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const inputStyle = {
    fontFamily: "monospace",
    MozAppearance: "textfield",
    borderRadius: "6px",
    border: "1px solid",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
    margin: "4px",
    paddingLeft: "8px",
    paddingRight: 0,
    paddingTop: 0,
    width: "36px",
    height: "44px",
    fontSize: "32px",
    boxSizing: "border-box",
  };

  return (
    <>
      <Typography.Title level={2}>
        {isPass ? "Enter new password" : "Enter reset code"}
      </Typography.Title>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem
              rules={[{ required: true }]}
              required
              name="code"
              key={"code"}
            >
              <ReactCodeInput
                type="text"
                disabled={isPass}
                fields={6}
                inputStyle={{
                  fontFamily: "monospace",
                  MozAppearance: "textfield",
                  borderRadius: "6px",
                  border: "1px solid",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
                  margin: "4px",
                  padding: 0,
                  lineHeight: "44px",
                  textAlign: "center",
                  width: "36px",
                  height: "44px",
                  fontSize: "25px",
                  boxSizing: "border-box",
                }}
                name={"code"}
                inputMode={"full-width-latin"}
                onChange={(value) => {
                  if (value.length == 6) checkCode();
                }}
              />
            </FormItem>
          </Col>
          {isPass && (
            <>
              {" "}
              <Col span={24}>
                <FormItem
                  rules={[{ required: true }]}
                  required
                  label="Password"
                  name="password"
                  key={"password"}
                >
                  <Input.Password />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please enter re-password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  required
                  label="Re-password"
                  name="rePassword"
                  key={"rePassword"}
                >
                  <Input.Password />
                </FormItem>
              </Col>
            </>
          )}
        </Row>
        <FormItem style={{ marginBottom: 0 }}>
          {isPass ? (
            <Button
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
              type="primary"
              size="large"
            >
              Reset password
            </Button>
          ) : (
            <Button
              onClick={() => checkCode()}
              style={{ width: "100%" }}
              loading={loading}
              type="primary"
              size="large"
            >
              Submit code
            </Button>
          )}
        </FormItem>
      </Form>
    </>
  );
};
