import { Collapse, Empty, List, Space, Tabs } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Service, ServiceOption } from "types/service";
import { formatVND } from "utils/formatVND";
import { secondToMinuteString } from "utils/generateDuration";
import { OptionList } from "./OptionList";

const { Panel } = Collapse;

export const ServiceList = ({ services }: { services: Partial<Service[]> }) => {
  const navigate = useNavigate();

  const getMinPrice = (serviceOptions: ServiceOption[]) => {
    const priceArr = serviceOptions.map((option) => option.price);
    return "$" + formatVND(Math.min(...priceArr));
  };

  const getMinDuration = (serviceOptions: ServiceOption[]) => {
    const durationArr = serviceOptions.map((option) => option.duration);
    return secondToMinuteString(Math.min(...durationArr));
  };
  //   return <>{services.map((service) => service?.name)}</>;
  const booking = (idService: number) => {
    navigate("./booking?serviceId=" + idService);
  };
  return (
    <>
      {services.map((service, index) => {
        return (
          <Panel
            showArrow={false}
            header={
              <Space
                onClick={() => booking(service?.id || 0)}
                className="service-item"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <div>
                  <b className="name">
                    {service?.serviceOptions?.[0].pricingName
                      ? service?.serviceOptions?.[0].pricingName
                      : service?.name}
                  </b>
                  <br />
                  <span className="time">
                    {" "}
                    {getMinDuration(service?.serviceOptions || [])}
                  </span>
                </div>
                <span className="price">
                  From <br />
                  <b> {getMinPrice(service?.serviceOptions || [])}</b>
                </span>
              </Space>
            }
            key={index}
          >
            {/* <OptionList options={service?.serviceOptions || []} /> */}
          </Panel>
        );
      })}

      {services.length == 0 && (
        <Empty description="There are no services for this category" />
      )}
    </>
  );
};
