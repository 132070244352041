import { Button, message, Result, Space, Spin, Steps } from "antd";
import { appointmentApi } from "api/appointment.api";
import { merchantApi } from "api/merchant.api";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { userStore } from "store/userStore";
import { Appointment } from "types/appointment";
import { Merchant } from "types/merchant";
import { Service } from "types/service";
import { getTitle } from "utils";
import { getToken } from "utils/auth";
import { checkSlug } from "utils/checkSlug";
import { SelectService } from "./Steps/SelectService";
import { SelectTechnician } from "./Steps/SelectTechnician";
import { SelectTime } from "./Steps/SelectTime";
import "./style/booking.scss";
const { Step } = Steps;

export const BookingPage = observer(({ title }: { title: string }) => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState<Appointment>();
  const [technicianSelected, setTechnicianSelected] = useState<number>();
  const [servicesSelected, setServicesSelected] = useState<Service[]>();
  const [appointmentInfo, setAppointmentInfo] = useState<Appointment>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [merchant, setMerchant] = useState<Merchant>();

  const [searchParam, setSearchParam] = useSearchParams();
  const param = useParams();
  const navigation = useNavigate();
  const timeOut = useRef<any>();

  useEffect(() => {
    document.title = getTitle(title);
    fetchMerchant();
  }, []);

  //Rebook feature
  useEffect(() => {
    const isUpdate = searchParam.get("status") == "update";
    setIsUpdate(isUpdate);
    const appoinmentId = searchParam.get("appointmentId");
    if (appoinmentId) {
      fetchAppointment(Number(appoinmentId));
    }
  }, [searchParam]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);

  const fetchAppointment = async (id: number) => {
    setLoading(true);
    const res = await appointmentApi.detail(id);
    setLoading(false);
    setAppointmentInfo(res.data);
  };

  const fetchMerchant = async () => {
    setLoading(true);
    let idMerchant = param.idMerchant;
    let res;
    const isSlug = checkSlug(param.idMerchant);
    if (isSlug) {
      res = await merchantApi.detailSlug(idMerchant);
    } else {
      res = await merchantApi.detail(idMerchant);
    }

    setMerchant(res.data);
    setLoading(false);
  };

  const steps = [
    {
      title: "Select Service",
      content: (
        <SelectService
          appointmentInfo={appointmentInfo}
          onSubmitOK={(serviceList) => {
            setServicesSelected(serviceList);
          }}
          onNextStep={() => {
            setCurrent(current + 1);
          }}
        />
      ),
    },
    {
      title: "Select Technician",
      content: (
        <SelectTechnician
          servicesSelected={servicesSelected}
          onSubmitOK={(id) => {
            setTechnicianSelected(id);
          }}
          onPrevious={() => setCurrent(current - 1)}
          onNextStep={() => {
            setCurrent(current + 1);
          }}
        />
      ),
    },
    {
      title: "Select time",
      content: (
        <SelectTime
          technicianId={technicianSelected}
          onPrevious={() => setCurrent(current - 1)}
          onSubmitOK={(bookingTime) => {
            submitBooking(bookingTime);
          }}
          // onNextStep={() => setCurrent(current + 1)}
        />
      ),
    },
    {
      title: "Done",
      content: (
        <>
          <Result
            status={"success"}
            title="Booking successfully!"
            subTitle={`Your order number: #${bookingInfo?.code}.`}
          />
        </>
      ),
    },
  ];

  const FINAL_PAGE = steps.length - 1;
  // const SERVICE_PAGE = 0;
  // const TECHNICIAN_PAGE = 1;
  // const DATE_PAGE = 2;

  const submitBooking = async (bookingTime: number) => {
    const details = servicesSelected?.map((service) => {
      return {
        duration: service.serviceOptions[0].duration,
        price: service.serviceOptions[0].price,
        serviceOptionId: service.serviceOptions[0].id,
        serviceId: service.id,
      };
    });

    const appointment = {
      note: "",
      firstServiceStart: bookingTime,
    };

    let idMerchant = param.idMerchant;
    const isSlug = checkSlug(param.idMerchant);

    let dataPost = {
      merchantId: idMerchant,
      technicianId: technicianSelected,
      details,
      appointment,
    };

    //Prevent submit merchantId=slug instead of submit merchantId=idMerchant
    if (isSlug) {
      const res = await merchantApi.detailSlug(param.idMerchant);
      const idMerchant = res.data.id;

      dataPost.merchantId = idMerchant;
    }

    //check login status
    const token = getToken();
    //if not login =>redirect to login page
    if (!token) {
      message.info("Please login to complete your booking!", 10);
      sessionStorage.setItem("bookingSession", JSON.stringify(dataPost));
      navigation("/login");
    } else {
      setLoading(true);
      try {
        let res: any;
        switch (isUpdate) {
          case true:
            const appointmentId = searchParam.get("appointmentId");
            res = await appointmentApi.update(Number(appointmentId) || 0, {
              ...dataPost,
            });
            message.success(
              "Successful update booking. You will be redirected to the Appointment page in 3 seconds"
            );
            setBookingInfo(appointmentInfo);
            break;

          default:
            res = await appointmentApi.create({ ...dataPost });
            message.success(
              "Successful booking. You will be redirected to the Appointment page in 3 seconds"
            );
            setBookingInfo(res.data);
            break;
        }
        setCurrent(current + 1);
        //Clear param

        timeOut.current = setTimeout(() => {
          navigation("/appointment");
        }, 2500);
      } finally {
        setLoading(false);
      }
    }
  };

  const resetBooking = () => {
    clearTimeout(timeOut.current);
    setAppointmentInfo(undefined);
    setCurrent(0);
  };

  return (
    // <Spin spinning={loading}>
    <>
      {merchant?.isEnableBookingOnline && !merchant.isEnableBookingOnline ? (
        <Result status="error" title="Booking online are disabled" />
      ) : (
        <div>
          <div className="container service-booking">
            <section>
              <Steps current={current} className="custom-steps">
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              {current === FINAL_PAGE && (
                <div className="steps-action">
                  <Space className="final-btn">
                    <Button
                      type="default"
                      className="custom-btn outline"
                      onClick={() => navigation("/appointment")}
                    >
                      View my appointment
                    </Button>
                    <Button
                      type="primary"
                      className="custom-btn"
                      onClick={resetBooking}
                    >
                      Book other services
                    </Button>
                  </Space>
                </div>
              )}
            </section>
          </div>
        </div>
      )}
    </>
  );
});
