import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const publicApi = {
  getAppointment: (id?: any): AxiosPromise<any> =>
    request({
      url: `/v1/public/appointment/${id}`,
    }),
  delete: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/public/appointment/${id}/cancel`,
      method: "delete",
      data,
    }),
};
