import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const merchantApi = {
  findAll: (params: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/merchant`,
      params,
    }),
  detail: (id: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/merchant/${id}`,
    }),
  detailSlug: (slug: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/merchant/slug/${slug}`,
    }),
  favorite: (params: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/merchant/favorite`,
      params,
    }),
};
