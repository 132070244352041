import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const serviceApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/service",
      params,
    }),
};
