import { message } from "antd";
import { appointmentApi } from "api/appointment.api";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userStore } from "store/userStore";
import { getToken, setToken } from "utils/auth";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleAuth();
    return () => {};
  }, [navigation]);

  const handleBookingSession = async () => {
    const bookingSession = sessionStorage.getItem("bookingSession");
    if (bookingSession) {
      const dataPost = JSON.parse(bookingSession);
      try {
        await appointmentApi.create({ ...dataPost });
        message.success(
          "Successful booking. You will be redirected to the Appointment page in 3 seconds"
        );

        setTimeout(() => {
          navigation("/appointment");
        }, 2500);
      } finally {
        sessionStorage.removeItem("bookingSession");
        return;
      }
    }
  };

  const handleAuth = async () => {
    const token = getToken();
    console.log("handle Auth", token);
    if (!token) {
      //Prevent login form cann't back
      navigation("/login", { state: { requireLogin: true } });
    } else {
      try {
        await userStore.getProfile();
        //check session booking
        handleBookingSession();
      } catch (error) {
        setToken("");
        navigation("/login");
      }
    }
  };

  return <>{children}</>;
};
