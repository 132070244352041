import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const rateApi = {
  getRate: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/rate",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/rate",
      data,
      method: "post",
    }),
  summary: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/rate/summary",
      params,
    }),

  publicRate: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/public/rate",
      data,
      method: "post",
    }),
};
