import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Technician as TechnicianType } from "types/technician";
import { BookingPage } from "views/BookingPage/BookingPage";
import { Technician } from "./components/Technician";
import "./style/technician.scss";

export const TechnicianList = ({
  technicians,
}: {
  technicians: TechnicianType[];
}) => {
  const navigate = useNavigate();
  const booking = (idTechnician: number) => {
    navigate(`./booking?technicianId=${idTechnician}`);
  };
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [isScrollStart, setIsScrollStart] = useState(true);
  const [visible, setVisible] = useState(false);

  const technicianBoxRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    console.log(technicianBoxRef);
    if (
      technicianBoxRef.current?.scrollWidth !=
      technicianBoxRef.current?.offsetWidth
    ) {
      setVisible(true);
    }
  }, []);

  //handle next/pre day
  useEffect(() => {
    technicianBoxRef.current?.scrollTo({ left: scrollOffset });
  }, [scrollOffset]);

  const next = () => {
    const maxWidth = technicianBoxRef.current?.scrollWidth;
    const offSetWidth = technicianBoxRef.current?.offsetWidth;

    //Check max scroll width
    if (offSetWidth && maxWidth && scrollOffset + offSetWidth < maxWidth) {
      setIsScrollStart(false);
      setScrollOffset((preValue) => preValue + offSetWidth);
    } else {
      setIsScrollEnd(true);
    }
  };

  const pre = () => {
    const offSetWidth = technicianBoxRef.current?.offsetWidth;
    if (offSetWidth && scrollOffset - offSetWidth > 0) {
      setScrollOffset((preValue) => preValue - offSetWidth);
      // console.log("scrollValue :>> ", scrollOffset + (offSetWidth || 0));
    } else {
      setIsScrollStart(true);
      setIsScrollEnd(false);
      setScrollOffset(0);
    }
  };

  return (
    <>
      <Divider />
      <Typography.Title level={1}>Book with Technician</Typography.Title>
      <div className="technician-list">
        {visible ? (
          <>
            {" "}
            <Button
              disabled={isScrollEnd}
              type="default"
              className="next btn-slider"
              onClick={next}
              icon={<ArrowRightOutlined />}
            ></Button>
            <Button
              disabled={isScrollStart}
              type="default"
              className="pre btn-slider"
              onClick={pre}
              icon={<ArrowLeftOutlined />}
            ></Button>
          </>
        ) : null}

        <Row
          gutter={16}
          className="technician-container"
          ref={technicianBoxRef}
        >
          {technicians.map((technician) => {
            return (
              <Col
                className="wrap-technician-item"
                span={6}
                xs={{ span: 10 }}
                md={{ span: 7 }}
                lg={{ span: 4 }}
                key={technician.id}
              >
                <Technician
                  key={technician.id}
                  technician={technician}
                  onClick={() => booking(technician.id)}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
