import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const authApi = {
  login: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/login",
      data,
      method: "post",
    }),

  loginGG: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/login/gg",
      data,
      method: "post",
    }),
  loginFB: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/login/fb",
      data,
      method: "post",
    }),

  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/register",
      data,
      method: "post",
    }),
  update: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/profile",
      data,
      method: "patch",
    }),
  passwordUpdate: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/password/update",
      data,
      method: "patch",
    }),

  profile: (): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/profile",
    }),

  forgotPassword: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/password/forgot",
      data,
      method: "post",
    }),
  confirmCode: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/password/forgot/confirm-code",
      data,
      method: "post",
    }),
  passwordReset: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/auth/password/reset",
      data,
      method: "post",
    }),
};
