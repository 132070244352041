import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const timeBlockApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/timeBlock",
      params,
    }),
};
