import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const technicianApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/technician",
      params,
    }),
  detail: (id: number): AxiosPromise<any> =>
    request({
      url: "/v1/customer/technician/" + id,
    }),
  availableServices: (data: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/technician/available-services`,
      method: "post",
      data,
    }),
};
