import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { authApi } from "api/auth.api";
import { E164Number } from "libphonenumber-js/min";
import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  const navigate = useNavigate();
  const rules: Rule[] = [{ required: true }];
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<E164Number>();

  const handleSubmit = async () => {
    setLoading(true);
    await form.validateFields();
    const { rePassword, ...dataPost } = form.getFieldsValue();
    try {
      await authApi.create({ ...dataPost });
      message.success("Register successfully!");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography.Title level={2}>Register</Typography.Title>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              rules={rules}
              required
              label="First name"
              name="firstName"
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem rules={rules} required label="Last name" name="lastName">
              <Input />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || isValidPhoneNumber(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Incorrect phone number format")
                    );
                  },
                }),
              ]}
              required
              label="Phone"
              name="phone"
              key={"phone"}
            >
              <PhoneInput
                addInternationalOption={false}
                countries={["US"]}
                placeholder="Enter phone number"
                defaultCountry="US"
                value={phone}
                onChange={(value: E164Number) => {
                  setPhone(value);
                }}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              rules={rules}
              required
              label="Email"
              name="email"
              key={"email"}
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              rules={rules}
              required
              label="Password"
              name="password"
              key={"password"}
            >
              <Input.Password />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              rules={[
                {
                  required: true,
                  message: "Please enter re-password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              required
              label="Re-password"
              name="rePassword"
              key={"rePassword"}
            >
              <Input.Password />
            </FormItem>
          </Col>
        </Row>
        <FormItem>
          <Button
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            size="large"
          >
            Sign up
          </Button>
        </FormItem>
      </Form>
    </>
  );
};
