import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import "./styles/ClientLayout.scss";

export const ClientLayout = () => {
  return (
    <Layout>
      <ScrollToTop>
        <Navbar />
        <Content style={{ paddingTop: "4.5em" }}>
          <Outlet />
        </Content>
        {/* <Footer /> */}
      </ScrollToTop>
    </Layout>
  );
};
