import { Divider, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ServiceCategory } from "types/service";
import { ServiceList } from "./ServiceList";
import "./style/serviceCategory.scss";

const { TabPane } = Tabs;

export const ServiceCategoryList = ({
  serviceCategory,
}: {
  serviceCategory: ServiceCategory[];
}) => {
  const [tabsPosition, setTabsPosition] = useState<"left" | "top">("left");

  useEffect(() => {
    if (window.innerWidth <= 760) {
      setTabsPosition("top");
    }
  }, []);

  return (
    <>
      <Divider />
      <Typography.Title level={1}>Services</Typography.Title>
      <Tabs
        className="category-list"
        defaultActiveKey="0"
        tabPosition={tabsPosition}
        type="line"
      >
        {serviceCategory.map((category, index) => {
          return (
            <Tabs.TabPane tab={category.name} key={index}>
              <ServiceList services={category.services} />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};
