import { MenuOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu, Space } from "antd";
import { Header } from "antd/lib/layout/layout";
import { SearchModal } from "components/Search/components/SearchModal";
import { Search } from "components/Search/Search";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "store/userStore";
import { getToken, setToken } from "utils/auth";
import { $url } from "utils/url";
import "../styles/ClientLayout.scss";

export const Navbar = observer(() => {
  const token = getToken();
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (token) setIsLogin(true);
  }, [token]);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          block
          style={{ textAlign: "left" }}
          type="text"
          onClick={() => navigate("/profile")}
        >
          Edit profile
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          block
          style={{ textAlign: "left" }}
          type="text"
          onClick={() => navigate("/appointment")}
        >
          My appointments
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          block
          style={{ textAlign: "left" }}
          danger
          type="text"
          onClick={() => {
            setToken("");
            navigate("/login");
          }}
        >
          Log out
        </Button>
      </Menu.Item>
    </Menu>
  );

  const mobileMenu = (
    <Menu className="dropdown-mobile">
      <Menu.Item key="1" onClick={() => navigate("/login")}>
        Log In
      </Menu.Item>
      <Menu.Item key="2" onClick={() => navigate("./login?type=register")}>
        Sign Up
      </Menu.Item>
    </Menu>
  );
  return (
    <Header style={{ position: "fixed", zIndex: 999, width: "100%" }}>
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="logo"
            onClick={() => navigate("/")}
            style={{ marginRight: "10px" }}
          >
            <img src={require("../../assets/images/logo.png")} alt="" />
          </div>
          {/* <Search /> */}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <Button
            type="text"
            icon={<SearchOutlined />}
            onClick={() => setVisible(true)}
            className="search-mobile-btn"
          /> */}
          {isLogin ? (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Avatar
                icon={<UserOutlined />}
                src={$url(userStore.info.customerProfile?.avatar)}
              />
            </Dropdown>
          ) : (
            <>
              <Dropdown
                className="mobile-menu"
                overlay={mobileMenu}
                trigger={["click"]}
              >
                <MenuOutlined />
              </Dropdown>

              <Menu mode="horizontal" className="desktop-menu">
                <Menu.Item key="1" onClick={() => navigate("/login")}>
                  Log In
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => navigate("./login?type=register")}
                >
                  Sign Up
                </Menu.Item>
              </Menu>
            </>
          )}
        </div>
      </Space>
      {/* <SearchModal visible={visible} onClose={() => setVisible(false)} /> */}
    </Header>
  );
});
