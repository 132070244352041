import { Divider, Space } from "antd";
import React from "react";
import { Appointment } from "types/appointment";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";
import { secondToMinuteString } from "utils/generateDuration";

export const AppointmentItem = ({
  appointment,
}: {
  appointment: Appointment;
}) => {
  return (
    <div className="appointment-card">
      <p style={{ marginBottom: "0.5em" }}>
        <b>{unixToFullTime(appointment.createdAt)}</b>
      </p>
      {appointment?.appointmentDetails &&
        appointment?.appointmentDetails.map((service, index) => (
          <div
            className={`service ${
              index == appointment?.appointmentDetails.length - 1
                ? "last-child"
                : null
            }`}
            key={service.id}
          >
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <p className="service-name">
                {service.serviceOption.pricingName
                  ? service.serviceOption.pricingName
                  : service.serviceOption.service.name}
              </p>
            </Space>
            <p className="time">
              {secondToMinuteString(service.serviceOption.duration)}{" "}
            </p>
          </div>
        ))}

      <div className="duration"></div>

      <p className="total">
        <b>Total: {"$" + formatVND(appointment.moneyTotal)}</b>
      </p>
    </div>
  );
};
