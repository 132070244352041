import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const appointmentApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/appointment",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/appointment",
      data,
      method: "post",
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/appointment/${id}/update`,
      data,
      method: "post",
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/customer/appointment/${id}/cancel`,
      method: "delete",
    }),
  upcoming: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/appointment/upcoming",
      params,
    }),
  past: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/appointment/past",
      params,
    }),
  detail: (id: number, params?: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/appointment/${id}`,
      params,
    }),
  getNew: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/appointment/new",
      params,
    }),
};
