import {
  AppstoreAddOutlined,
  FacebookFilled,
  GlobalOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  Rate as RateAntd,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { appointmentApi } from "api/appointment.api";
import { merchantApi } from "api/merchant.api";
import { promotionApi } from "api/promotion.api";
import { rateApi } from "api/rate.api";
import { serviceCategoryApi } from "api/serviceCategory.api";
import { technicianApi } from "api/technician.api";
import CarouselComponent from "components/Carousel/Carousel";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Appointment } from "types/appointment";
import { Merchant } from "types/merchant";
import { Promotion, PromotionType } from "types/promotion";
import { ServiceCategory } from "types/service";
import { Technician } from "types/technician";
import { getTitle } from "utils";
import { getToken } from "utils/auth";
import { checkSlug } from "utils/checkSlug";
import { isEmptyObject } from "utils/emptyObjectCheck";
import { renderDayTitle } from "utils/renderDayTitle";
import locationIcon from "../../assets/images/location-icon.png";
import { AppointmentList } from "./components/AppointmentList/AppointmentList";
import { RateSection } from "./components/Rate/Rate";
import { ServiceCategoryList } from "./components/ServiceCategoryList/ServiceCategoryList";
import { TechnicianList } from "./components/TechnicianList/TechnicianList";
import { VoucherList } from "./components/VoucherList/VoucherList";
import "./style/merchantDetail.scss";

export const MerchantDetailPage = ({ title }: { title: string }) => {
  const param = useParams();
  const navigate = useNavigate();

  const [merchant, setMerchant] = useState<Merchant>();
  const [vouchers, setVouchers] = useState<Promotion[]>();
  const [services, setServices] = useState<ServiceCategory[]>();
  const [appointments, setAppointments] = useState<Appointment[]>();
  const [technicians, setTechnicians] = useState<Technician[]>();
  const [rateSummary, setRateSummary] =
    useState<{ total: number; star: number }[]>();
  const [token, setToken] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [totalRate, setTotalRate] = useState(0);
  const [totalStar, setTotalStar] = useState(0);
  const [infoBoxHeight, setInfoBoxHeight] = useState(0);

  const infoBox = useRef<HTMLDivElement | null>(null);
  const caroselBox = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  const fetchData = async (isSlug: boolean) => {
    setLoading(true);
    let res;
    let merchantQuery = param.idMerchant;
    console.log("check Slug :>> ", isSlug);
    if (isSlug) {
      res = await merchantApi.detailSlug(param.idMerchant || "");
      merchantQuery = res.data.id;
    } else {
      res = await merchantApi.detail(param.idMerchant || 0);
    }
    //Check if merchant not found => return 404
    if (isEmptyObject(res.data)) {
      navigate("/404");
      console.log();
    }
    const resVoucher = await promotionApi.findAll({
      type: PromotionType.Normal,
      merchantId: merchantQuery,
      limit: 3,
      page: 1,
    });
    const resServcie = await serviceCategoryApi.findAll({
      merchantId: merchantQuery,
      showOnBookingOnline: true,
    });
    const resRate = await rateApi.summary({ merchantId: merchantQuery });
    const resTechnician = await technicianApi.findAll({
      merchantId: merchantQuery,
    });
    if (token) {
      const resAppointment = await appointmentApi.findAll();
      setAppointments(resAppointment.data.appointments);
    }
    setLoading(false);
    setMerchant(res.data);
    setVouchers(resVoucher.data.promotions);
    setServices(resServcie.data.serviceCategories);
    setRateSummary(resRate.data);
    setTechnicians(resTechnician.data.technicians);
  };

  useEffect(() => {
    var isSlug = checkSlug(param.idMerchant);
    fetchData(isSlug);

    const token = getToken();
    setToken(token);
  }, []);

  useEffect(() => {
    if (merchant) {
      console.log("infoBoxHeight", infoBox.current?.offsetHeight);
      const infoBoxHeight = infoBox.current?.offsetHeight;
      setInfoBoxHeight(infoBoxHeight || 0);
    }
  }, [merchant]);

  //calc rate
  useEffect(() => {
    const totalRate = rateSummary?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.total;
      },
      0
    );

    const totalStar = rateSummary?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.star * rate.total;
      },
      0
    );
    setTotalRate(totalRate);
    setTotalStar(totalStar);
  }, [rateSummary]);

  const calcAverageStar = useMemo(() => {
    if (totalRate == 0 || totalStar) return 0;
    return (totalStar / totalRate).toFixed(1);
  }, [totalRate, totalStar]);

  return (
    <Spin spinning={loading}>
      <div className="container merchant-detail-page">
        <section>
          <Row gutter={32} className="banner">
            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }}>
              <div className="merchant-info" id="merchant-info" ref={infoBox}>
                <h1 className="name uppercase">{merchant?.name}</h1>
                <p className="address">{merchant?.address}</p>
                <div className="rating">
                  <Space>
                    <RateAntd defaultValue={5} disabled />
                    {merchant?.rateStar &&
                      merchant.rateCount &&
                      Math.round(merchant?.rateStar / merchant?.rateCount)}
                    star
                  </Space>

                  <p className="sub-title">{totalRate} ratings</p>
                </div>
                {merchant?.isEnableBookingOnline && (
                  <Button
                    type="primary"
                    block
                    onClick={() => navigate("./booking")}
                  >
                    Book Now
                  </Button>
                )}
              </div>
            </Col>
            <Col span={16} xs={{ span: 24 }} lg={{ span: 16 }}>
              <CarouselComponent
                images={merchant?.merchantGalleries || []}
                height={infoBoxHeight}
              />
            </Col>
          </Row>
          <Row
            gutter={64}
            style={{ marginTop: "2em" }}
            className="merchant-contact"
          >
            <Col span={16} xs={{ span: 24 }} lg={{ span: 16 }}>
              <Typography.Title level={1}>
                About <span className="uppercase">{merchant?.name}</span>
              </Typography.Title>
              <p className="desc">{merchant?.introduce}</p>
              <div className="social">
                {merchant?.website && (
                  <>
                    <a href={merchant?.website}>
                      <Space>
                        <GlobalOutlined /> Website
                      </Space>
                    </a>
                    <br />
                  </>
                )}

                {merchant?.facebook && (
                  <a href={merchant?.facebook}>
                    <Space>
                      <FacebookFilled />
                      Facebook
                    </Space>
                  </a>
                )}
              </div>
              <Space wrap style={{ width: "100%" }} className="btn-group">
                <Button
                  type="primary"
                  size="small"
                  icon={<AppstoreAddOutlined />}
                  onClick={() => navigate("./booking")}
                >
                  Book Your Appointment
                </Button>
                <Button
                  type="primary"
                  size="small"
                  icon={<ProfileOutlined />}
                  onClick={() => navigate("/appointment")}
                >
                  Manage Your Appointment
                </Button>
              </Space>
            </Col>
            <Col
              span={8}
              xs={{ span: 24 }}
              lg={{ span: 8 }}
              className="location"
            >
              <Typography.Title level={1}>Location</Typography.Title>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <p className="address">{merchant?.address}</p>
                <img src={locationIcon} alt="" width={80} />
              </Space>
              <Divider />
              <div className="Open hours">
                <Typography.Title level={1}>Open hours</Typography.Title>
                {merchant?.workingHourJson &&
                  JSON.parse(merchant?.workingHourJson).map(
                    (hours: {
                      day: string;
                      enable: boolean;
                      from: string;
                      to: string;
                    }) => {
                      return (
                        <Row>
                          <Col span={10}>{renderDayTitle(hours.day)}</Col>
                          <Col span={14} style={{ textAlign: "right" }}>
                            {hours.enable
                              ? hours.from + " - " + hours.to
                              : "Closed"}
                          </Col>
                        </Row>
                      );
                    }
                  )}
              </div>
            </Col>
          </Row>
          {appointments && appointments?.length > 0 ? (
            <AppointmentList appointments={appointments} />
          ) : null}
          {vouchers && vouchers?.length > 0 ? (
            <VoucherList vouchers={vouchers} />
          ) : null}
          {services && services?.length > 0 ? (
            <ServiceCategoryList serviceCategory={services} />
          ) : null}
          {technicians && technicians.length > 0 ? (
            <TechnicianList technicians={technicians} />
          ) : null}
          <RateSection merchantId={merchant?.id || 0} />
        </section>
      </div>
    </Spin>
  );
};
