import { Button, Col, Divider, Empty, message, Row, Space, Spin } from "antd";
import { serviceApi } from "api/service.api";
import { serviceCategoryApi } from "api/serviceCategory.api";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Appointment, AppointmentDetail } from "types/appointment";
import { Service, ServiceCategory } from "types/service";
import { checkSlug } from "utils/checkSlug";
import { ServiceCateBooking } from "./ServiceCateBooking";

export const SelectService = ({
  appointmentInfo,
  onSubmitOK,
  onNextStep,
}: {
  appointmentInfo?: Appointment;
  onSubmitOK: (serviceList: Service[]) => void;
  onNextStep: () => void;
}) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [services, setServices] = useState<Service[]>();
  const [loading, setLoading] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [serviceSelectedIds, setServiceSelectedIds] = useState<number[]>([]);
  const [serviceCate, setServiceCate] = useState<ServiceCategory[]>();
  const [selectedCateId, setSelectedCateId] = useState<number>();
  const param = useParams();

  //Get service by id merchant
  useEffect(() => {
    if (param.idMerchant) {
      setMerchantId(param.idMerchant);
      console.log("id", param.idMerchant);
    }
  }, []);

  // Auto select service by AppointmentInfo
  useEffect(() => {
    if (appointmentInfo) {
      const servicesId = appointmentInfo.appointmentDetails.map(
        (appointment) => {
          return appointment.serviceOption.service.id;
        }
      );
      setServiceSelectedIds(servicesId);

      console.log("selected", servicesId);
    }

    console.log("appointmentInfo", appointmentInfo);
  }, [appointmentInfo]);

  useEffect(() => {
    if (merchantId) {
      fetchService();
    }
  }, [merchantId]);

  //Get service id param
  useEffect(() => {
    if (searchParam.get("serviceId")) {
      const idService = Number(searchParam.get("serviceId"));
      setServiceSelectedIds([idService]);
    }
  }, [searchParam.get("serviceId")]);

  const fetchService = async () => {
    setLoading(true);
    const isSlug = checkSlug(merchantId);
    let res;
    if (isSlug) {
      res = await serviceApi.findAll({
        merchantSlug: merchantId,
        showOnBookingOnline: true,
      });
    } else {
      res = await serviceApi.findAll({ merchantId, showOnBookingOnline: true });
    }

    setLoading(false);
    setServices(res.data.services);
  };

  const handleSelectService = (id: number) => {
    //check ton tai
    if (serviceSelectedIds.length > 0 && serviceSelectedIds.includes(id)) {
      const newSelected = serviceSelectedIds.filter((value) => value != id);
      setServiceSelectedIds([...newSelected]);
    } else {
      //them vao mang
      setServiceSelectedIds([...serviceSelectedIds, id]);
      console.log("select", [...serviceSelectedIds, id]);
    }
  };

  const handleSubmit = () => {
    //Validate
    console.log("step1 submit", serviceSelectedIds);
    if (serviceSelectedIds.length > 0) {
      const serviceData = services?.filter((service) =>
        serviceSelectedIds.includes(service.id)
      );
      console.log("serviceData", serviceData);

      //Truyen du lieu ve booking page
      onSubmitOK(serviceData || []);
      onNextStep();
    } else {
      message.error("Please select service");
    }
  };

  return (
    <Spin spinning={loading}>
      <ServiceCateBooking
        merchantId={merchantId}
        onSelectCate={(cateId) => setSelectedCateId(cateId)}
      />
      <Divider />
      <h1 style={{ marginBottom: "0.5em" }}>Select service </h1>
      <Row
        gutter={24}
        className="select-service-box"
        style={{ marginBottom: "2em" }}
      >
        {services
          ?.filter((service) => service?.serviceCategory?.id == selectedCateId)
          .map((service) => {
            const selectedClass = serviceSelectedIds.includes(service.id)
              ? "service-selected"
              : null;

            return (
              <Col
                lg={{ span: 8 }}
                md={{ span: 12 }}
                xs={{ span: 24 }}
                key={service.id}
                style={{ marginBottom: "1.5em" }}
              >
                <div
                  className={`service ${selectedClass}`}
                  onClick={() => handleSelectService(service.id)}
                >
                  {service.serviceOptions[0].pricingName || service.name}
                </div>
              </Col>
            );
          })}
        {services?.filter(
          (service) => service?.serviceCategory?.id == selectedCateId
        ).length == 0 && (
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Empty />
          </Space>
        )}
      </Row>
      <div className="steps-action">
        <Button
          className="custom-btn"
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </div>
    </Spin>
  );
};
