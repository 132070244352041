import { Form, Input, message, Modal, Rate } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { rateApi } from "api/rate.api";
import React, { useEffect, useState } from "react";
import { Appointment } from "types/appointment";

export const ReviewModal = ({
  visible,
  appointment,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  appointment?: Appointment;
  onClose: () => void;
  onSubmitOk: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const submitForm = async () => {
    const dataPost = form.getFieldsValue();
    setLoading(true);
    try {
      await rateApi.create({
        ...dataPost,
        merchantId: appointment?.merchant.id,
        appointmentId: appointment?.id,
      });
      message.success("Your review was sent");
    } finally {
      setLoading(false);
      onClose();
      onSubmitOk();
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      title="Review"
      confirmLoading={loading}
      onOk={submitForm}
    >
      <Form layout="vertical" form={form}>
        <FormItem key="star" name="star">
          <Rate />
        </FormItem>
        <FormItem label="Your review" key="comment" name="comment">
          <Input />
        </FormItem>
      </Form>
    </Modal>
  );
};
