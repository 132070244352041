import { Col, Row, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../AppointmentPage/style/appointment.scss";
import { AppointmentDetailPublic } from "./components/PublicAppointmentDetail";

export const AppointmentPagePublic = ({ title }: { title: string }) => {
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const param = useParams();

  return (
    <Spin spinning={loading}>
      <div className="appointment-page appointment-public-page">
        <div className="container">
          <Row style={{ paddingTop: "2em" }}>
            <Col xl={10} xs={24} style={{ margin: "auto" }}>
              {param.publicCode && (
                <AppointmentDetailPublic
                  publicCode={param.publicCode}
                  onSubmitOK={(appointmentId) => {
                    setSelectedId(appointmentId);
                  }}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};
