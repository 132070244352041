export function generateDuration() {
  let seconds = 300;
  const durations: { label: string; value: number }[] = [];
  while (seconds <= 60 * 60 * 12) {
    const h = Math.floor(seconds / 3600);
    const m = (seconds % 3600) / 60;
    let label = secondToMinuteString(seconds);
    durations.push({
      label,
      value: seconds,
    });
    seconds += 300;
  }
  return durations;
}

export const secondToMinuteString = (second: any) => {
  const h = Math.floor(second / 3600);
  const m = (second % 3600) / 60;
  let label = "";
  if (h == 0) {
    label = `${m}m`;
  } else {
    if (m == 0) {
      label = `${h}h`;
    } else {
      label = `${h}h ${m}m`;
    }
  }
  return label;
};
