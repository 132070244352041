import { Button, Form, message, Modal, Row, Typography } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { authApi } from "api/auth.api";
import { E164Number } from "libphonenumber-js/types";
import React, { useState } from "react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
//@ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin, GoogleLoginResponse } from "react-google-login";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { userStore } from "store/userStore";
import { setToken } from "utils/auth";
import facebookIcon from "../../../assets/images/facebook-icon.png";
import googleIcon from "../../../assets/images/google-icon.png";

export const LoginSocial = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [dataLogin, setDataLogin] = useState<any>({});

  const [phone, setPhone] = useState<E164Number>();

  const rules: Rule[] = [{ required: true }];

  //Google
  const responseGoogle = async (response: any) => {
    const res: GoogleLoginResponse = response;
    if (res.googleId) {
      const dataPost = {
        ggToken: res.googleId,
        email: res.profileObj.email,
        firstName: res.profileObj.givenName,
        lastName: res.profileObj.familyName,
      };

      //If(new user => show modal)
      const resLogin = await userStore.loginGG(dataPost.ggToken);
      if (!resLogin) {
        setVisible(true);
        setDataLogin(dataPost);
      } else {
        await userStore.getProfile();
        navigate("/");
      }
      console.log("resLogin", resLogin);
    } else {
      message.error("Google login failed");
      console.error("error", res);
    }
  };

  //Facebook
  const responseFacebook = async (response: any) => {
    const res: ReactFacebookLoginInfo = response;
    console.log(response);
    if (res.accessToken) {
      const nameArr = res.name?.split(" ");
      const firsName = nameArr ? nameArr[0] : "";
      const lastName = nameArr ? nameArr[nameArr.length - 1] : "";
      const dataPost = {
        fbToken: res.id,
        email: res.email,
        firstName: firsName,
        lastName: lastName,
      };

      const resLogin = await userStore.loginFB(dataPost.fbToken);
      console.log("facebook-res", resLogin);

      if (!resLogin) {
        setDataLogin(dataPost);
        setVisible(true);
      } else {
        await userStore.getProfile();
        navigate("/");
      }
    } else {
      message.error("Facebook login failed");
    }
  };

  const submitForm = async () => {
    await form.validateFields();
    // const phone = form.getFieldValue("phone");
    if (dataLogin) {
      const res = await authApi.create({ ...dataLogin, phone });
      setToken(res.data.token);
      await userStore.getProfile();
      navigate("/");
    } else {
      message.error("Something went wrong, Please login again");
      setVisible(false);
    }
  };

  return (
    <Row gutter={16}>
      <Typography.Title level={2}>Login for bookers</Typography.Title>
      <Button block type="primary" onClick={() => navigate("?type=loginPhone")}>
        Login with phone number
      </Button>

      <GoogleLogin
        clientId="435084890024-1iltbp2uvp963ruakaet68bbdn6dggfl.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            className="btn-social"
            onClick={renderProps.onClick}
            // disabled={renderProps.disabled}
          >
            <div className="img-box">
              <img src={googleIcon} alt="" />
            </div>{" "}
            <p className="btn-label">Continue with Google</p>
          </button>
        )}
        autoLoad={false}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />

      <FacebookLogin
        appId="3289252381307153"
        fields="name,email,picture"
        callback={responseFacebook}
        render={(renderProps: any) => (
          <button className="btn-social" onClick={renderProps.onClick}>
            <div className="img-box">
              <img src={facebookIcon} alt="" />
            </div>{" "}
            <p className="btn-label">Continue with Facebook</p>
          </button>
        )}
      />

      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        title={"Enter your phone number"}
        visible={visible}
        onOk={submitForm}
        onCancel={() => {
          message.warning("Login has been cancel");
          setVisible(false);
        }}
      >
        <Form layout="vertical" form={form}>
          <FormItem
            label="Phone"
            name="phone"
            key="phone"
            required
            rules={[
              {
                required: true,
                message: "Please enter your phone number!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || isValidPhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Incorrect phone number format")
                  );
                },
              }),
            ]}
          >
            <PhoneInput
              addInternationalOption={false}
              countries={["US"]}
              defaultCountry="US"
              placeholder="Enter phone number"
              value={phone}
              onChange={(value: E164Number) => setPhone(value)}
            />
          </FormItem>
        </Form>
      </Modal>
    </Row>
  );
};
