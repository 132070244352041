import {
  CheckCircleFilled,
  CheckOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import React from "react";
import { Technician as TechnicianType } from "types/technician";

export const Technician = ({
  technician,
  selectedId,
  onClick,
}: {
  technician?: TechnicianType;
  selectedId?: number;
  onClick?: () => void;
}) => {
  const selectedClass = selectedId == technician?.id ? "avatar-selected" : null;
  const showClass = selectedId == technician?.id ? "show" : null;
  return (
    <div className={`technician-item`} onClick={onClick}>
      <Avatar
        icon={<UserOutlined />}
        src={technician?.avatar}
        className={selectedClass || ""}
      />

      <div className={`selected-overplay ${showClass}`}>
        <CheckOutlined />
      </div>

      <div className="name">
        {!technician
          ? "No Preference"
          : technician.nickname ||
            technician?.firstName + " " + technician?.lastName}
      </div>
    </div>
  );
};
