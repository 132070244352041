import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoutes } from "router";
import { getToken } from "utils/auth";

export const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // const token = getToken();
    // if (location.pathname == "/") {
    //   if (token) {
    //     navigate(adminRoutes[0]?.path || "/login");
    //   } else {
    //     navigate("/login");
    //   }
    // }
  }, [location]);

  return location;
};
