import { authApi } from "api/auth.api";
import { action, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { Customer } from "types/customer";
import { getToken, setToken } from "utils/auth";

class UserStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UserStore",
      properties: ["info"],
      storage: window.localStorage,
    });
  }

  info: Partial<Customer> = {};

  @action
  async login(phone: string, password: string) {
    const res = await authApi.login({ phone, password });

    setToken(res.data.token);
    console.log("get token", getToken());
  }

  @action
  async loginGG(ggToken: string) {
    const dataPost = { ggToken };
    const res = await authApi.loginGG({ ...dataPost });
    if (!res.status) {
      return false;
    } else {
      setToken(res.data.token);
      console.log("get token", getToken());
      return true;
    }
  }

  @action
  async loginFB(fbToken: string) {
    const dataPost = { fbToken };
    const res = await authApi.loginFB({ ...dataPost });
    console.log("facebook api", res.status);

    if (!res.status) {
      return false;
    } else {
      setToken(res.data.token);
      console.log("get token", getToken());
      return true;
    }
  }

  @action
  async getProfile() {
    const res = await authApi.profile();
    this.info = res.data;
  }
}

const userStore = new UserStore();

export { userStore };
