import { Button, Col, Modal, Row, Spin, Typography } from "antd";
import { appointmentApi } from "api/appointment.api";
import moment from "moment";
import { AuthProvider } from "provider/AuthProvider";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Appointment } from "types/appointment";
import { getTitle } from "utils";
import { getToken } from "utils/auth";
import { AppointmentDetail } from "./components/AppointmentDetail";
import { AppoitmentItem } from "./components/AppointmentItem";
import "./style/appointment.scss";

export const AppointmentPage = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<Appointment[]>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const upcomingAppointments = data?.filter(
    (appointment) => appointment.firstServiceStart >= moment().unix()
  );

  const pastAppointments = data?.filter(
    (appointment) => appointment.firstServiceStart < moment().unix()
  );

  useEffect(() => {
    const token = getToken();
    if (token) {
      fetchData();
      document.title = getTitle(title);
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const res = await appointmentApi.findAll();
    setLoading(false);
    setData(res.data.appointments);
    if (!selectedId) {
      setSelectedId(res.data.appointments[0].id);
    }
  };

  return (
    <AuthProvider>
      <Spin spinning={loading}>
        <div className="appointment-page">
          <div className="container">
            <Row gutter={24} style={{ paddingTop: "2em" }}>
              <Col md={{ span: 24 }} xs={{ span: 24 }} lg={{ span: 8 }}>
                {upcomingAppointments && upcomingAppointments?.length > 0 ? (
                  <div>
                    <Typography.Title level={3}>
                      Upcoming appointments
                    </Typography.Title>
                    <div className="appointment-list customer-scrollbar">
                      {upcomingAppointments?.map((appointment) => {
                        return (
                          <AppoitmentItem
                            onClick={() => {
                              var deviceWidth =
                                window.innerWidth > 0
                                  ? window.innerWidth
                                  : undefined;
                              //Open modal on mobile
                              console.log("width", deviceWidth);

                              if (deviceWidth && deviceWidth <= 850) {
                                setVisible(true);
                                console.log("width", deviceWidth);
                              }
                              setSelectedId(appointment.id);
                            }}
                            key={appointment.id}
                            appointment={appointment}
                            selectedId={selectedId}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <>
                    <h2>No upcoming appointments</h2>
                    <p>
                      Have fun making some! Any booking you make will show up
                      here.
                    </p>
                    {/* <Button type="primary" onClick={() => navigate("/search")}>
                      Find service near you
                    </Button> */}
                  </>
                )}

                {pastAppointments && pastAppointments?.length > 0 ? (
                  <div style={{ marginTop: "1em" }}>
                    {" "}
                    <Typography.Title level={3}>
                      Past appointments
                    </Typography.Title>
                    <div className="appointment-list customer-scrollbar">
                      {pastAppointments?.map((appointment) => {
                        return (
                          <AppoitmentItem
                            onClick={() => {
                              var deviceWidth =
                                window.innerWidth > 0
                                  ? window.innerWidth
                                  : undefined;
                              //Open modal on mobile
                              console.log("width", deviceWidth);

                              if (deviceWidth && deviceWidth <= 850) {
                                setVisible(true);
                              }
                              setSelectedId(appointment.id);
                            }}
                            key={appointment.id}
                            appointment={appointment}
                            selectedId={selectedId}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : undefined}
              </Col>
              <Col span={16}>
                <AppointmentDetail
                  appointmentId={selectedId}
                  onSubmitOK={fetchData}
                />
              </Col>
            </Row>
          </div>
        </div>

        {/* Modal mobile */}
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          className="modal-fullwidth appointment-modal"
          footer={false}
        >
          <AppointmentDetail
            appointmentId={selectedId}
            onSubmitOK={(appointmentId) => {
              fetchData();
              setSelectedId(appointmentId);
            }}
          />
        </Modal>
      </Spin>
    </AuthProvider>
  );
};
