import { CustomerProfile } from "./customerProfile";
import { Merchant } from "./merchant";
import { Rate } from "./rate";
import { Service } from "./service";

export interface Appointment {
  id: number;
  createdAt: number;
  updatedAt: number;
  note: string;
  isDeleted: boolean;
  code: string;
  status: AppointmentStatus;
  firstServiceStart: number;
  serviceEnd: number;
  reasonCancel: string;
  isRated: boolean;
  appointmentDetails: AppointmentDetail[];
  merchant: Merchant;
  customerProfile: CustomerProfile;
  checkout: Checkout;
  rate: Rate;
  appointmentHistories: AppointmentHistory[];
  moneyTotal: number;
}

export interface AppointmentDetail {
  id: number;
  createdAt: number;
  updatedAt: number;
  start: number;
  duration: number;
  isFavorite: boolean;
  appointment: Appointment;
  serviceOption: ServiceOption;
}

export interface AppointmentHistory {
  id: number;
  createdAt: number;
  updatedAt: number;
  type: string;
  content: string;
  appointment: Appointment;
}

export interface ServiceOption {
  id: number;
  createdAt: number;
  updatedAt: number;
  duration: number;
  price: number;
  pricingName: string;
  service: Service;
}

export enum PromotionKind {
  Promotion = "PROMOTION",
  Reward = "REWARD",
}

export enum AppointmentStatus {
  New = "NEW",
  Completed = "COMPLETED",
  Cancel = "CANCEL",
  NoShow = "NO_SHOW",
}

export enum DiscountBy {
  Amount = "AMOUNT",
  Percent = "PERCENT",
}

export enum PromotionType {
  Normal = "NORMAL", //
  Reminder = "REMINDER", //Nhắc khách lâu quá chưa quay lại
  Birthday = "BIRTHDAY", //Khách có sinh nhật
}

export interface Checkout {
  id: number;
  createdAt: number;
  updatedAt: number;
  status: CheckoutStatus;
  emailSentInvoice: string;
  note: string;
  refundPoint: number; //Số điểm khách dc nhận khi checkout
  code: string;
  completeAt: number; // Hoan thanh hoa don luc
  voidedAt: number; // Huy hoa don luc
  voucherAmount: number; // Thanh toan bang voucher bao nhieu cho checkout nay
  moneyTotal: number; //  Tiền tạm tính(dịch vụ)  -> 1
  tax: number; // Thuế -> 2
  moneyDiscount: number; // Tiền giảm -> 3
  moneyFinal: number; // Tong tien invoice -> 4: 1 + 2 - 3
  // checkIn: CheckIn;
  // checkoutDetails: CheckoutDetail[];
  // tips: Tip[];
  customerProfile: CustomerProfile;
  merchant: Merchant;
  appointment: Appointment;
  // checkoutPayments: CheckoutPayment[];
  // promotion: Promotion;
}

export enum CheckoutStatus {
  Complete = "COMPLETE",
  Voided = "VOIDED",
  Refunded = "REFUNDED",
}
