import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Progress,
  Rate,
  Row,
  Space,
  Typography,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import { rateApi } from "api/rate.api";
import React, { useEffect, useMemo, useState } from "react";
import { RateQueryParam } from "types/query";
import { Rate as rateType } from "types/rate";
import { AllReviewModal } from "./components/AllReviewModal";
import { Comment } from "./components/Comment";
import "./style/rate.scss";

export const RateSection = ({
  merchantId,
  rates,
}: {
  merchantId: number;
  rates?: rateType[];
}) => {
  const [ratesFiltered, setRatesFiltered] = useState<rateType[]>();
  const [rateSummary, setRateSummary] =
    useState<{ total: number; star: number }[]>();
  const [totalRate, setTotalRate] = useState(0);
  const [totalStar, setTotalStar] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [checkedValue, setCheckedValue] = useState<any>();
  const [query, setQuery] = useState<RateQueryParam>({
    page: 1,
    limit: 5,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    fetchSummary();
    setQuery({ ...query, merchantId: merchantId });
  }, [merchantId]);

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = async () => {
    setLoading(true);
    const res = await rateApi.getRate(query);

    setLoading(false);
    setRatesFiltered(res.data.rates);
  };

  const fetchSummary = async () => {
    setLoading(true);
    const summaryRes = await rateApi.summary({ merchantId });

    setLoading(false);

    setRateSummary(summaryRes.data);
  };

  useEffect(() => {
    const totalRate = rateSummary?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.total;
      },
      0
    );

    const totalStar = rateSummary?.reduce(
      (total: any, rate: { total: number; star: number }) => {
        return total + rate.star * rate.total;
      },
      0
    );
    setTotalRate(totalRate);
    setTotalStar(totalStar);
  }, [rateSummary]);

  // const calcAverageStar = () => {
  //   if (totalRate == 0) return 0;
  //   return (totalStar / totalRate).toFixed(2);
  // };

  const calcAverageStar = useMemo(() => {
    if (totalRate == 0) return 0;
    return (totalStar / totalRate).toFixed(1);
  }, [totalRate, totalStar]);

  const calcStarPercent = (star: number) => {
    const totalOfStarItem = rateSummary?.find(
      (rate) => rate.star == star
    )?.total;
    if (totalOfStarItem && totalOfStarItem > 0) {
      return (totalOfStarItem * 100) / totalRate;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Divider />
      <Typography.Title level={1}>Review</Typography.Title>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} lg={{ span: 9 }} style={{ marginBottom: "2em" }}>
          <div className="review-container">
            <div className="review">
              <Rate disabled defaultValue={5} /> <br />
              <Space>
                <p>
                  <b>{calcAverageStar} Star </b>
                  <span>{totalRate} ratings</span>
                </p>
              </Space>
            </div>
            <div className="filter">
              <p>
                <b>Filter by rating</b>
              </p>
              <Form form={form}>
                {rateSummary?.map((rate, index) => {
                  const isDisabled = rate.total == 0 ? true : false;

                  return (
                    <Row gutter={16} key={index}>
                      <Col xs={2}>
                        <FormItem shouldUpdate noStyle>
                          {() => {
                            return (
                              <FormItem name="rate" key={index}>
                                <Checkbox
                                  checked={checkedValue == rate.star}
                                  value={rate.star}
                                  disabled={isDisabled}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setCheckedValue(rate.star);
                                      console.log("checkedd", e.target.value);
                                      setQuery({
                                        ...query,
                                        star: rate.star,
                                      });
                                    } else {
                                      setCheckedValue(undefined);
                                      setQuery({ ...query, star: undefined });
                                    }
                                  }}
                                />{" "}
                              </FormItem>
                            );
                          }}
                        </FormItem>
                      </Col>
                      <Col xs={10}>
                        <Rate disabled defaultValue={rate.star} />
                      </Col>
                      <Col xs={11}>
                        <Progress
                          format={() => rate.total}
                          percent={calcStarPercent(rate.star)}
                          status="normal"
                          showInfo
                          type="line"
                          strokeColor={"#fadb14"}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Form>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 15 }} xs={{ span: 24 }} className="comment-box">
          {ratesFiltered?.map((rate) => {
            return <Comment data={rate} key={rate.id} />;
          })}
          {totalRate > 5 && (
            <Button onClick={() => setVisible(true)} type="default">
              See all {totalRate} reviews
            </Button>
          )}
        </Col>
      </Row>

      <AllReviewModal
        onClose={() => setVisible(false)}
        visible={visible}
        merchantId={merchantId}
      />
    </>
  );
};
