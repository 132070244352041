import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import { Appointment } from "types/appointment";
import { AppointmentItem } from "./components/AppointmentItem";
import "./style/appointmentList.scss";

export const AppointmentList = ({
  appointments,
}: {
  appointments: Appointment[];
}) => {
  return (
    <>
      <Divider />
      <Typography.Title level={1}>Your appointments</Typography.Title>
      <Row gutter={16} className="appointment-list">
        {appointments.map((appointment) => {
          return (
            <Col
              xs={{ span: 20 }}
              md={{ span: 11 }}
              lg={{ span: 6 }}
              key={appointment.id}
              style={{ marginBottom: "1em" }}
            >
              <AppointmentItem appointment={appointment} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
