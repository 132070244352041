import React from "react";
import { Appointment } from "types/appointment";
import { unixToDate } from "utils/formatDate";

export const AppoitmentItem = ({
  appointment,
  selectedId,
  onClick,
}: {
  appointment: Partial<Appointment>;
  selectedId: number;
  onClick: () => void;
}) => {
  return (
    <div
      className={`appointment-item ${
        selectedId == appointment.id ? "active" : null
      }`}
      onClick={onClick}
    >
      <div className="avatar">
        <img src={appointment?.merchant?.avatar} />
      </div>
      <div className="info">
        <p className="date">
          {appointment?.firstServiceStart &&
            unixToDate(appointment?.firstServiceStart)}
        </p>
        <div className="merchant-name">{appointment?.merchant?.name}</div>
        <p className="id">#{appointment.code}</p>
      </div>
    </div>
  );
};
