import { Col, Divider, Row, Typography } from "antd";
import { Voucher } from "components/Voucher/Voucher";
import React from "react";
import { Promotion } from "types/promotion";
import "./style/voucherList.scss";

export const VoucherList = ({ vouchers }: { vouchers: Promotion[] }) => {
  return (
    <>
      <Divider />
      <Typography.Title level={1}>Vouchers</Typography.Title>
      <Row gutter={12} className="voucher-list mobile-slide-container">
        {vouchers.map((voucher) => {
          return (
            <Col
              className="voucher-wrap"
              span={8}
              xs={{ span: 20 }}
              md={{ span: 14 }}
              lg={{ span: 8 }}
              key={voucher.id}
              style={{ marginBottom: "1em" }}
            >
              <Voucher data={voucher} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
