import {
  FacebookFilled,
  FacebookOutlined,
  GoogleCircleFilled,
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

export const VoteSocial = ({
  fbLink,
  googleLink,
}: {
  fbLink: string | undefined;
  googleLink: string | undefined;
}) => {
  return (
    <div className="vote-social">
      <h4>Tap on Google or Facebook option below to vote!</h4>
      {googleLink && (
        <a
          href={googleLink}
          target="_blank"
          className="button button-full button-l button-round-huge shadow-huge bg-google"
        >
          <Button
            style={{ marginBottom: "1em" }}
            block
            icon={<GoogleCircleFilled />}
            className="google-btn"
          >
            Google
          </Button>
        </a>
      )}
      {fbLink && (
        <a href={fbLink} target="_blank">
          <Button block icon={<FacebookFilled />} className="fb-btn">
            Facebook
          </Button>
        </a>
      )}
    </div>
  );
};
