import React, { useEffect } from "react";

import "styles/App.scss";
import "styles/AntDesign.scss";
import "antd/dist/antd.less";
import { useRoutes } from "react-router-dom";
import { routes } from "router";
import { ConfigProvider } from "antd";
import { useRouter } from "hooks/useRouter";

function App() {
  const element = useRoutes(routes);
  useRouter();

  return (
    <ConfigProvider>
      <div className="App">{element}</div>
    </ConfigProvider>
  );
}

export default App;
