import { Button, Form, Input, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { E164Number } from "libphonenumber-js/min";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { userStore } from "store/userStore";

export const LoginPhone = () => {
  const [form] = Form.useForm<{ phone: string; password: string }>();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<E164Number>();
  const navigation = useNavigate();

  const handleSubmit = async () => {
    console.log("handleSubmit:");
    setLoading(true);
    const { phone, password } = form.getFieldsValue();
    try {
      await userStore.login(phone, password);
      await userStore.getProfile();
      navigation("/");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Typography.Title level={2}>Login for bookers</Typography.Title>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <FormItem label="Phone" name="phone" key={"phone"}>
          <PhoneInput
            placeholder="Enter phone number"
            addInternationalOption={false}
            countries={["US"]}
            defaultCountry="US"
            value={phone}
            onChange={(value: E164Number) => {
              setPhone(value);
            }}
          />
          {/* <Input /> */}
        </FormItem>
        <FormItem
          style={{ marginBottom: "0" }}
          label="Password"
          name="password"
          key={"password"}
        >
          <Input.Password />
        </FormItem>
        <Button
          type="link"
          onClick={() => navigation("/forgot-password")}
          style={{ paddingLeft: 0 }}
        >
          Forgot Password?
        </Button>
        <FormItem>
          <Button
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            size="large"
          >
            Login
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};
