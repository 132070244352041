import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
  RedoOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Button, Empty, message, Modal, Space, Spin, Typography } from "antd";
import { appointmentApi } from "api/appointment.api";
import { publicApi } from "api/public.api";
import { ReviewModal } from "components/ReviewModal/ReviewModal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Appointment, AppointmentStatus } from "types/appointment";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";
import { secondToMinuteString } from "utils/generateDuration";

export const AppointmentDetailPublic = ({
  publicCode,
  onSubmitOK,
}: {
  publicCode: string;
  onSubmitOK: (appointmentId: number) => void;
}) => {
  const [data, setData] = useState<Appointment>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (publicCode) fetchData(publicCode);
  }, [publicCode]);

  console.log("publicCode", publicCode);
  console.log("abc");

  const fetchData = async (idAppointment: string) => {
    setLoading(true);
    const res = await publicApi.getAppointment(idAppointment);
    setLoading(false);
    setData(res.data);
  };

  const confirm = () => {
    Modal.confirm({
      title: `Confirm cancel`,
      icon: <ExclamationCircleOutlined />,
      content: `Confirm cancel this appointment ?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleDeleteCancel(),
    });
  };

  const handleDeleteCancel = async () => {
    const res = await publicApi.delete(data?.id || 0, {
      appointmentId: data?.id || 0,
    });
    message.success("Cancel successfully");
    // setData(res);
    console.log("res", res);
    onSubmitOK(data?.id || 0);
    fetchData(publicCode);
  };

  if (data) {
    return (
      <div className="appointment-detail">
        <Spin spinning={loading}>
          <div className="header">
            <Typography.Title level={1}>
              {data?.createdAt && unixToFullTime(data.firstServiceStart)}
            </Typography.Title>
            {data?.status == AppointmentStatus.Cancel ? (
              <div className="badge">CANCEL</div>
            ) : null}
            <Space className="merchant">
              <img className="avatar" src={data?.merchant?.avatar} alt="" />
              <div className="info">
                <Link
                  to={`../merchant/${data.merchant.slug || data.merchant.id}`}
                >
                  {console.log("Slug", data.merchant.slug)}
                  <h1>{data?.merchant?.name}</h1>
                </Link>

                <p>{data?.merchant?.address}</p>
              </div>
            </Space>
            <br />
            {/* <Space style={{ marginTop: "0.5em" }}> */}
            {data?.isDeleted ? (
              <Button
                type="primary"
                style={{ marginTop: "1em" }}
                icon={<RedoOutlined />}
                onClick={() =>
                  navigate(
                    `../merchant/${data?.merchant?.id}/booking?appointmentId=${data?.id}`
                  )
                }
              >
                Rebook
              </Button>
            ) : (
              <Space>
                <Button
                  type="primary"
                  style={{ marginTop: "1em" }}
                  icon={<RedoOutlined />}
                  onClick={() =>
                    navigate(`../merchant/${data?.merchant?.id}/booking`)
                  }
                >
                  Book another service
                </Button>
                {/* <Button
                  type="primary"
                  style={{ marginTop: "1em" }}
                  icon={<EnvironmentFilled />}
                >
                  Direction
                </Button> */}
                {data?.checkout ? (
                  <>
                    {data?.isRated ? (
                      <Button
                        type="primary"
                        style={{ marginTop: "1em" }}
                        icon={<StarFilled />}
                        disabled
                      >
                        Rated
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        style={{ marginTop: "1em" }}
                        icon={<StarFilled />}
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        Rate
                      </Button>
                    )}{" "}
                  </>
                ) : null}

                {data?.firstServiceStart &&
                data?.firstServiceStart > moment().unix() &&
                data.status != AppointmentStatus.Cancel ? (
                  <Button
                    onClick={confirm}
                    type="primary"
                    style={{ marginTop: "1em" }}
                    icon={<CloseCircleFilled />}
                    danger
                  >
                    Cancel
                  </Button>
                ) : null}
              </Space>
            )}
            {/* </Space> */}
          </div>
          <div className="content">
            <div className="content-detail">
              {data?.appointmentDetails &&
                data?.appointmentDetails.map((service) => (
                  <div className="service" key={service.id}>
                    <Space
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <b className="service-name">
                        {service.serviceOption.service.name}
                      </b>
                      <p className="service-info">
                        {"$" +
                          formatVND(
                            service.serviceOption.price ||
                              service.serviceOption.service.price
                          )}
                      </p>
                    </Space>
                    <p className="time">
                      {secondToMinuteString(service.serviceOption.duration)}{" "}
                      {service.serviceOption.pricingName}
                    </p>
                  </div>
                ))}
              <Space
                className="total"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <b>Total (#{data?.code})</b>
                <b>{"$" + formatVND(data?.moneyTotal || 0)}</b>
              </Space>
            </div>
            {data?.merchant?.importantInfo ? (
              <div style={{ marginTop: "1em" }}>
                <p style={{ marginBottom: "5px" }}>
                  <strong>Important Info</strong>
                </p>
                <p>{data.merchant?.importantInfo}</p>
              </div>
            ) : null}
          </div>
        </Spin>
        <ReviewModal
          onSubmitOk={() => fetchData(publicCode)}
          appointment={data}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      </div>
    );
  } else {
    return (
      <div className="appointment-detail empty">
        <Empty description="public" />
      </div>
    );
  }
};
