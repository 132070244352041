import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  message,
  Rate,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { publicApi } from "api/public.api";
import { rateApi } from "api/rate.api";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Appointment, AppointmentStatus } from "types/appointment";
import { unixToFullTime } from "utils/formatDate";
import { formatVND } from "utils/formatVND";
import { secondToMinuteString } from "utils/generateDuration";
import "../AppointmentPage/style/appointment.scss";
import { VoteSocial } from "./components/VoteSocial";
import "./ratePublic.scss";

export const RatePublicPage = ({ title }: { title: string }) => {
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [appointment, setAppointment] = useState<Appointment>();
  const [showInput, setShowInput] = useState(false);
  const [showVoteSocial, setShowVoteSocial] = useState(false);
  const [form] = useForm();

  const param = useParams();

  useEffect(() => {
    if (param.publicCode) {
      fetchAppointment(param.publicCode);
    }
  }, [param.publicCode]);

  const fetchAppointment = async (idAppointment: string) => {
    let res;
    setLoading(true);
    try {
      res = await publicApi.getAppointment(idAppointment);
      setLoading(false);
      setAppointment(res.data);
    } finally {
      setLoading(false);
    }
  };

  const handleRate = (rate: number) => {
    if (rate >= 4) {
      setShowInput(false);
      submitRate();
    } else {
      setShowInput(true);
    }
  };

  const submitRate = async () => {
    const { star, comment } = form.getFieldsValue();

    await rateApi.publicRate({
      star,
      comment,
      appointmentPublicCode: param.publicCode,
    });

    if (star >= 4) {
      setShowVoteSocial(true);
    }
    message.success("Your review was sent");
    fetchAppointment(param.publicCode || "");
  };

  return (
    <Spin spinning={loading}>
      <div className="appointment-page public-rate-page appointment-public-page">
        <div className="container">
          <Row style={{ paddingTop: "2em" }}>
            <Col xl={10} xs={24} style={{ margin: "auto" }}>
              <div className="appointment-detail">
                {appointment ? (
                  <Spin spinning={loading}>
                    <div className="header">
                      <Typography.Title level={1}>
                        {appointment?.createdAt &&
                          unixToFullTime(appointment.firstServiceStart)}
                      </Typography.Title>
                      {appointment?.status == AppointmentStatus.Cancel ? (
                        <div className="badge">CANCEL</div>
                      ) : null}
                      <Space className="merchant">
                        <img
                          className="avatar"
                          src={appointment?.merchant?.avatar}
                          alt=""
                        />
                        <div className="info">
                          <Link
                            to={`../merchant/${
                              appointment?.merchant.slug ||
                              appointment?.merchant.id
                            }`}
                          >
                            {console.log("Slug", appointment?.merchant.slug)}
                            <h1>{appointment?.merchant?.name}</h1>
                          </Link>

                          <p>{appointment?.merchant?.address}</p>
                        </div>
                      </Space>
                      <br />
                    </div>
                    <div className="content">
                      <div className="content-detail">
                        {appointment?.appointmentDetails &&
                          appointment?.appointmentDetails.map((service) => (
                            <div className="service" key={service.id}>
                              <Space
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <b className="service-name">
                                  {service.serviceOption.service.name}
                                </b>
                                <p className="service-info">
                                  {"$" +
                                    formatVND(
                                      service.serviceOption.price ||
                                        service.serviceOption.service.price
                                    )}
                                </p>
                              </Space>
                              <p className="time">
                                {secondToMinuteString(
                                  service.serviceOption.duration
                                )}{" "}
                                {service.serviceOption.pricingName}
                              </p>
                            </div>
                          ))}
                        <Space
                          className="total"
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <b>Total (#{appointment?.code})</b>
                          <b>{"$" + formatVND(appointment?.moneyTotal || 0)}</b>
                        </Space>
                      </div>
                      {appointment?.merchant?.importantInfo ? (
                        <div style={{ marginTop: "1em" }}>
                          <p style={{ marginBottom: "5px" }}>
                            <strong>Important Info</strong>
                          </p>
                          <p>{appointment.merchant?.importantInfo}</p>
                        </div>
                      ) : null}

                      {appointment?.status == AppointmentStatus.Completed && (
                        <div className="rate-container">
                          <h3>Rate us</h3>
                          <Form layout="vertical" form={form}>
                            <FormItem noStyle name={"star"}>
                              <Rate
                                disabled={appointment.isRated}
                                defaultValue={appointment?.rate?.rateStar}
                                style={{ fontSize: "3em" }}
                                onChange={(rate) => handleRate(rate)}
                              />
                            </FormItem>
                            {console.log(
                              "comment",
                              appointment?.rate?.rateComment
                            )}
                            {(showInput || appointment?.rate?.rateComment) && (
                              <>
                                <FormItem
                                  name={"comment"}
                                  label="Tell us your experience"
                                  style={{ marginTop: "1em" }}
                                >
                                  <Input.TextArea
                                    rows={4}
                                    defaultValue={
                                      appointment?.rate?.rateComment
                                    }
                                    readOnly={appointment.isRated}
                                  />
                                </FormItem>
                                {!appointment.isRated && (
                                  <FormItem>
                                    <Button
                                      block
                                      type="primary"
                                      size="large"
                                      onClick={submitRate}
                                    >
                                      Submit
                                    </Button>
                                  </FormItem>
                                )}
                              </>
                            )}
                          </Form>
                          {appointment.isRated &&
                            appointment?.rate?.rateStar >= 4 && (
                              <VoteSocial
                                fbLink={appointment.merchant.facebook || ""}
                                googleLink={appointment.merchant.google || ""}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </Spin>
                ) : (
                  <Empty
                    style={{
                      minHeight: "300px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};
