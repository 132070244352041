import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { authApi } from "api/auth.api";
import React, { useState } from "react";

const { Text } = Typography;

export const ForgotPassword = ({
  goToResetCode,
}: {
  goToResetCode: () => void;
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await form.validateFields();
    const email = form.getFieldsValue();
    try {
      await authApi.forgotPassword(email);
      message.success("Please check your email", 10);
      goToResetCode();
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography.Title level={2}>Forgot password</Typography.Title>
      <Text type="secondary" style={{ marginBottom: "1em", display: "block" }}>
        Enter your registered email address and we'll send you a secure link to
        setup a new password.
      </Text>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem
              rules={[{ type: "email" }, { required: true }]}
              required
              label="Email"
              name="email"
              key={"email"}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <FormItem>
          <Button
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            size="large"
          >
            Reset password
          </Button>
        </FormItem>
      </Form>
    </>
  );
};
