import moment from "moment";

export const unixToDate = (unixValue: number) => {
  const dateFormat = moment
    .unix(unixValue)
    .format(process.env.REACT_APP_DATE_FORMAT);
  return dateFormat;
};

export const unixToTime = (unixValue: number) => {
  const dateFormat = moment
    .unix(unixValue)
    .format(process.env.REACT_APP_TIME_FORMAT);
  return dateFormat;
};

export const unixToFullTime = (unixValue: number) => {
  const dateFormat = moment.unix(unixValue).format("MMMM DD YYYY, hh:mm A");
  return dateFormat;
};
