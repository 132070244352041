import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ForgotPassword } from "views/ForgotPasswordPage/steps/ForgotPassword";
import logo from "../../assets/images/logo.png";
import { ResetCode } from "./steps/ResetCode";

// import "";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams("");
  const [forgotParam, setForgotParam] = useState("");
  const location = useLocation();

  useEffect(() => {
    setForgotParam(searchParams.get("step") || "");
    console.log(searchParams.get("step"));
  }, [searchParams]);

  const handleBack = () => {
    // @ts-ignore:
    if (location?.state?.requireLogin) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="login-page">
      <div>
        <div>
          <div className="login-header">
            <Button type="text" onClick={handleBack} className="btn-back">
              <ArrowLeftOutlined />
            </Button>
            <img
              src={logo}
              alt=""
              className="logo"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="login-container">
          {forgotParam == "reset-code" ? (
            <ResetCode />
          ) : (
            <ForgotPassword
              goToResetCode={() => navigate("?step=reset-code")}
            />
          )}
        </div>
      </div>
    </div>
  );
};
